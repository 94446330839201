import React, { useState } from 'react'
import { 
  AddFolderButtonParentGrid,
  AddFolderInnerGridBackgroundContainer
} from './styled';
import { Button, Dialog, Grid, Typography } from '@mui/material';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { useDispatch } from 'react-redux';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
import { useAuth0 } from '@auth0/auth0-react';
import gtmTrackButtonClick from '../../utils/gtmTrackButtonClick';
import { createUserFolder } from '../../actions/documentActions';
import { SaveResponseTextField } from '../GPTChat/styled';

function AddFolderButton() {

  const dispatch = useDispatch();
  const userInfo = useCurrentUserInfo();
  const { isAuthenticated } = useAuth0();

  const [newFolderName, setNewFolderName] = useState(null);
  const [addNewFolder, setAddNewFolder] = useState(false);

  const closeAddFolderDialog = () => {
    setAddNewFolder(false);
  };

  const openAddFolderDialog = () => {
    setAddNewFolder(true);
  };

  const handleAddNewFolder = (e) => {
    gtmTrackButtonClick(e, 'click_addFolder');

    if(isAuthenticated){
      dispatch(
        createUserFolder({
          userId: userInfo?.user_id,
          newFolderName: newFolderName
        })
      );
    };

    setAddNewFolder(false);
  };

  return (
    <>
      <AddFolderButtonParentGrid container onClick={openAddFolderDialog}>
        <AddFolderInnerGridBackgroundContainer container>
          <CreateNewFolderOutlinedIcon sx={{color: 'white', fontSize: '2rem', marginRight: '1rem'}} />

          <Typography variant='h5' sx={{color: 'white'}}>
            New Folder
          </Typography>
        </AddFolderInnerGridBackgroundContainer>
      </AddFolderButtonParentGrid>
      <Dialog
        open={addNewFolder}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid
          container 
          sx={{
            display: 'flex', 
            flexDirection: 'column', 
            width: '20rem', 
            height: '100%', 
            padding: '0 1rem 1rem 1rem', 
          }}
        >
          <Grid
            item 
            sx={{
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              padding: '1rem 0'
            }}
          >
            <Grid sx={{display: 'flex', alignItems: 'center'}}> 
              <CreateNewFolderIcon sx={{marginRight: '1rem', fontSize: '2rem', color: 'gray'}} />
              <Typography variant='h6' sx={{color: 'gray'}}>Add New Folder</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <SaveResponseTextField
              fullWidth 
              id="outlined-basic" 
              label="Folder Name" 
              variant="outlined" 
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              sx={{marginBottom: '1rem'}} 
            />
          </Grid>
          <Grid
            item 
            sx={{
              display: 'flex', 
              justifyContent: 'flex-end', 
              alignItems: 'center', 
            }}
          >
            <Button
              variant='outlined' 
              sx={{
                padding: '0.25rem 1rem', 
                color: 'gray', 
                borderColor: 'lightgray', 
                marginRight: '0.5rem'
              }} 
              onClick={closeAddFolderDialog}
            >
              <Typography>
                Cancel
              </Typography>
            </Button>
            <Button 
              variant='contained' 
              disabled={!newFolderName} 
              sx={{
                padding: '0.25rem 1rem',
                background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`,
                color: 'white'
              }} 
              onClick={handleAddNewFolder}
            >
              <Typography>
                Confirm
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default AddFolderButton;