export const augmentSharedWithCollaboratorInfo = (selectedFileData, allUsersCollaborators) => {
  if (!selectedFileData?.sharedWith || !Array.isArray(selectedFileData.sharedWith)) {
    console.error('Invalid sharedWith data');
    return selectedFileData;
  };

  if (!allUsersCollaborators || !Array.isArray(allUsersCollaborators)) {
    console.error('Invalid allUsersCollaborators data');
    return selectedFileData;
  };

  // Iterate over the sharedWith array and enhance each object with the corresponding collaborator's data
  const augmentedSharedWith = selectedFileData?.sharedWith?.map(sharedUser => {

    // Find the collaborator in allUsersCollaborators using the userId
    const collaborator = allUsersCollaborators.find(user => user.user_id === sharedUser.user_id);

    // If a match is found, add the collaborator's data to the sharedUser object
    if (collaborator) {
      return {
        ...sharedUser,
        username: collaborator.username,
        picture: collaborator.picture,
        email: collaborator.email,
      };
    } 
    
    // If no match is found, return the sharedUser object as is
    return sharedUser;
  });

  // Return the newly augmented selectedFileData
  return {
    ...selectedFileData,
    sharedWith: augmentedSharedWith,
  };
};

export const mergeCollaborators = (collaborators, folderCollaborators) => {
  // Create a Map from collaborators for quick access
  const collaboratorsMap = new Map();

  if(collaborators){
    for (const collaborator of collaborators) {
      collaboratorsMap.set(collaborator.user_id, collaborator);
    };
  };

  if(folderCollaborators){
    // Iterate over folderCollaborators and check for user_id matches
    for (const folderCollaborator of folderCollaborators) {
      const userId = folderCollaborator.user_id;
      if (collaboratorsMap.has(userId)) {
        // If a collaborator with the same user_id exists, replace it
        collaboratorsMap.set(userId, collaboratorsMap.get(userId));
      } else {
        // If no collaborator exists, add the folderCollaborator
        collaboratorsMap.set(userId, folderCollaborator);
      };
    };
  };


  // Convert the map values back to an array
  return Array.from(collaboratorsMap.values());
};