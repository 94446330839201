import React, { 
  useEffect, 
  useState 
} from 'react';
import { 
  ParentGrid 
} from './styled';
import { useSelector } from 'react-redux';
import { 
  useMediaQuery 
} from '@mui/material';
import { useParams } from 'react-router-dom';
import DemoFilesMobile from './DemoFilesMobile';
import DemoFilesDesktop from './DemoFilesDesktop';
import { 
  ownedMessageFolders, 
  sharedMessageFolders,
  filteredDocs, 
  ownedMessages
} from './constants';

function FoldersContainer() {

  const params = useParams();

  const externalMessages = useSelector((state) => state.gptChat.externalDbMessages);

  const [selectedFolder, setSelectedFolder] = useState(ownedMessageFolders[0]);
  const [managerView, setManagerView] = useState('Folders');
  const [selectedFileData, setSelectedFileData] = useState(null);

	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {

    if(params?.folderId){
      const findFolderById = [...ownedMessageFolders, ...sharedMessageFolders]?.find(
        (folder => folder?._id === params?.folderId)
      );

      setSelectedFolder(findFolderById);
      setManagerView('Files');
      return;
    };
  
    if (params?.messageId) {
      const _id = parseInt(params.messageId);
      const updatedFileData = [
        ...(ownedMessages ?? [])      
      ].find((msg) => msg._id === _id);
  
      setSelectedFileData(updatedFileData || null);
      setManagerView('Document');

      const updatedFolder = ownedMessageFolders?.find(
        (folder) => folder?._id === updatedFileData?.folderId
      );

      if(updatedFolder){
        setSelectedFolder(updatedFolder);
      };

    } else {
      const updatedFolder = ownedMessageFolders?.find(
        (folder) => selectedFolder?._id === folder?._id
      );

      if(updatedFolder){
        setSelectedFolder(updatedFolder);
      };

      setManagerView('Folders')
    };
    //eslint-disable-next-line
  }, [
    ownedMessages, 
    externalMessages, 
    ownedMessageFolders, 
    sharedMessageFolders,
    params
  ]);

  return (
    <ParentGrid container>
      {
        isMobile ? (
          <DemoFilesMobile
            selectedFolder        = { selectedFolder       }
            setSelectedFolder     = { setSelectedFolder    }
            filteredDocs          = { filteredDocs         }
            ownedMessageFolders   = { ownedMessageFolders  }
            sharedMessageFolders  = { sharedMessageFolders } 
            managerView           = { managerView          }
            selectedFileData      = { selectedFileData     }
            setSelectedFileData   = { setSelectedFileData  }
            setManagerView        = { setManagerView       }
          />
        ) : (
          <DemoFilesDesktop
            selectedFolder        = { selectedFolder       }
            setSelectedFolder     = { setSelectedFolder    }
            filteredDocs          = { filteredDocs         }
            ownedMessageFolders   = { ownedMessageFolders  }  
            sharedMessageFolders  = { sharedMessageFolders }
            managerView           = { managerView          }
            selectedFileData      = { selectedFileData     }
            setSelectedFileData   = { setSelectedFileData  }
            setManagerView        = { setManagerView       }
          />
        )
      }
    </ParentGrid>
  )
}

export default FoldersContainer;