import { FormControl, Grid, InputLabel, MenuItem } from '@mui/material';
import React from 'react'
import { CustomTextField, FolderSelect } from './styled';
import { useSelector } from 'react-redux';
import FolderIcon from '@mui/icons-material/Folder';
import { CustomTypography } from '../GPTChat/styled';

function EditDocDetails({
  documentFolderId,
  setDocumentFolderId,
  ownedMessageFolders,
  userIsOwner,
  documentName,
  setDocumentName,
}) {
  
  const ownedFolders = useSelector((state) => state?.gptChat?.ownedFolders);
  const selectedFolder = ownedFolders?.find(folder => folder._id === documentFolderId);
  
  return (
    <Grid 
      sx={{ 
        maxHeight: '6rem', 
        width: '100%', 
        display: 'flex', 
        alignItems: 'center'
      }}
    >
      <FormControl sx={{ m: 1, minWidth: 150, color: 'black', flex: '1' }}>
        <InputLabel id="select-folder-label" sx={{color: 'black'}}>
          Folder
        </InputLabel>
        <FolderSelect
          labelId="select-folder-label"
          id="select-folder"
          sx={{color: 'black', borderColor: 'black'}}
          value={selectedFolder}
          label="Folder"
          disabled={!userIsOwner()}
          onChange={(e) => setDocumentFolderId(e.target.value)}
          renderValue={(selected) => {
            return(
              <CustomTypography>
                <FolderIcon sx={{ color: 'gray', flex: '1' }} /> 
                <Grid sx={{flex: '4'}}>
                  {selected?.name}
                </Grid>
              </CustomTypography>
            )
          }}
        >
          {
            ownedMessageFolders?.map((folder) => (
              <MenuItem key={folder?._id} value={folder?._id}>
                {folder?.name}
              </MenuItem>
            ))
          }
        </FolderSelect>
      </FormControl>
      <CustomTextField
        multiline
        rows={1}
        value={documentName}
        sx={{
          color: 'black', 
          flex: '2',
          display: 'flex', 
          width: '100%',
          height: '100%',
          marginRight: '1rem',
        }}
        onChange={(e) => setDocumentName(e.target.value)}
        disabled={!userIsOwner()}
      />
    </Grid>
  )
}

export default EditDocDetails;