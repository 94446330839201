import React, { useMemo, useState } from 'react'
import { useAppContext } from '../../unregisteredViews/LiveDemo/context';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import MobileView from '../../utils/isMobile';

function DemoDialog({ onClose }) {

  const {
    setState,
    state,
  } = useAppContext();
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  const [showDialog, setShowDialog] = useState(false);

  const showDialogConditional = (demoState) => {
    if(!demoState?.tourActive || !demoState?.run){
      setShowDialog(true);
      return;
    };

    setShowDialog(false);
  };

  const handleRestartTour = () => {
    setState({ run: true, tourActive: true, stepIndex: 0 })
    navigate('/demo/ai-chat');
  };

  const handleLogin = () => {
    loginWithRedirect({
      appState: {
        returnTo: '/dashboard'
      }
    });
  };

  const handleRegister = () => {
    loginWithRedirect({
      authorizationParams: { screen_hint: "signup" }
    });
  };

  useMemo(() => {
    showDialogConditional(state)
  }, [state]);

  return (
    <Dialog
      open={showDialog}
      onClose={onClose}
      aria-labelledby="options-dialog-title"
      fullWidth
      maxWidth="md"
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <DialogTitle 
        id="options-dialog-title"
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
        }}
      >
        <Typography sx={{fontSize: '1.5rem'}}>
          Register Free or Sign In!
        </Typography>
      </DialogTitle>

      <DialogActions 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          width: '100%',
          height: '15rem',
          justifyContent: 'space-between'
        }}
      >
        <Grid 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'space-between',
            height: '5rem'
          }}
        >
          <Button 
            onClick={handleLogin} 
            color="primary" 
            variant='outlined'
            sx={{ width: '15rem', marginBottom: '1rem' }}
          >
            <Typography variant='h6'>
              Sign In
            </Typography>
          </Button>
          <Button 
            onClick={handleRegister} 
            color="primary" 
            variant='contained'
            sx={{ width: '15rem', color: 'white' }}
          >
            <Typography variant='h6'>
              Register Free
            </Typography>
          </Button>
        </Grid>
        <Grid>
          <Button 
            onClick={handleRestartTour} 
            color="primary" 
            sx={{ width: '15rem'}}
          >
            <Typography variant='h6'>
              Restart Tour
            </Typography>
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default DemoDialog;