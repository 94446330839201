import React, { useMemo, useState } from 'react'
import { 
  DocumentListGrid, 
  FileListGrid, 
  InnerGridBackgroundContainer 
} from './styled';
import FolderListItem from '../../components/FolderListItem';
import DocumentListItem from '../../components/DocumentListItem';
import FolderControls from '../../components/FolderControls';
import DocDetailView from '../../containers/DocumentManager/DocDetailView';
import AddFolderButton from '../../containers/DocumentManager/AddFolderButton';

function DemoFilesDesktop({
  selectedFolder,
  setSelectedFolder,
  filteredDocs,
  ownedMessageFolders,
  sharedMessageFolders,
  managerView,
  selectedFileData,
  setSelectedFileData,
  setManagerView,
  handleDeleteFile,
}) {

  const collaborators = [];

  const [editDoc, setEditDoc] = useState(false);
  const [documentContent, setDocumentContent] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [documentFolderId, setDocumentFolderId] = useState(null);
  const [collaboratorsOpen, setCollaboratorsOpen] = useState(false);
  const [docsToMove, setDocsToMove] = useState([]);
  const [folderCollaborators, setFolderCollaborators] = useState([]);
  const [docCollaboratorChats, setDocCollaboratorChats] = useState([]);

  const ownedFolderIds = ['1'];
  
  const userIsOwner = () => {
    if(selectedFileData?.ownerId !== true){
      return false;
    };

    return true;
  };

  const userCanEdit = () => {
    return true;
  };

  useMemo(() => {

    if(selectedFileData){
      setDocumentContent(selectedFileData?.content);
      setDocumentName(selectedFileData?.docName);
      setDocumentFolderId(selectedFileData?.folderId);
      setDocCollaboratorChats(selectedFileData?.collabChats);
    };

  }, [selectedFileData]);

  return (
    <>
      {
        managerView === 'Folders' || managerView === 'Files' ? (
          <>
            <FileListGrid container id={'demo-folder-list'}>
              <InnerGridBackgroundContainer>
                <AddFolderButton />
                <FolderListItem
                  folderName={'All'}
                  folderId={0}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder} 
                  setManagerView={setManagerView}
                />
                {
                  ownedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={false}
                      selectedFolder={selectedFolder}
                      setSelectedFolder={setSelectedFolder} 
                      setManagerView={setManagerView}
                    />
                  ))
                }
                {
                  sharedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={true}
                      selectedFolder={selectedFolder}
                      setSelectedFolder={setSelectedFolder} 
                      setManagerView={setManagerView}
                    />
                  ))
                }
              </InnerGridBackgroundContainer>
            </FileListGrid>
            <DocumentListGrid container >
              <InnerGridBackgroundContainer sx={{ justifyContent: 'flex-start' }} >
                {
                  selectedFolder && (
                    <FolderControls 
                      folderCollaborators={folderCollaborators}
                      setFolderCollaborators={setFolderCollaborators}
                      selectedFolder={selectedFolder}
                      ownedFolderIds={ownedFolderIds}
                      setSelectedFolder={setSelectedFolder}
                      demoActive={true}
                    />
                  )
                }
                <div id={'demo-folder-document-list'}>
                  {
                    filteredDocs?.map((message, index) => (
                      <DocumentListItem 
                        key={index}
                        messageData={message} 
                        setSelectedFileData={setSelectedFileData}
                        setManagerView={setManagerView}
                        setDocsToMove={setDocsToMove}
                        docsToMove={docsToMove}
                      />
                    ))
                  }
                </div>
              </InnerGridBackgroundContainer>
            </DocumentListGrid>
          </>
        ) : (
          <DocDetailView 
            setManagerView={setManagerView}
            setEditDoc={setEditDoc}
            editDoc={editDoc}
            documentFolderId={documentFolderId}
            setDocumentFolderId={setDocumentFolderId}
            userIsOwner={userIsOwner}
            documentName={documentName}
            setDocumentName={setDocumentName}
            setCollaboratorsOpen={setCollaboratorsOpen}
            collaborators={collaborators}
            collaboratorsOpen={collaboratorsOpen}
            selectedFileData={selectedFileData}
            handleDeleteFile={handleDeleteFile}
            documentContent={documentContent}
            setDocumentContent={setDocumentContent}
            docCollaboratorChats={docCollaboratorChats}
            documentId={selectedFileData?._id}
            folderCollaborators={folderCollaborators}
            userCanEdit={userCanEdit}
          />
        )
      }
    </>
  )
}

export default DemoFilesDesktop;