import React from 'react'
import { Menu, MenuItem } from '@mui/material';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
import { useAuth0 } from '@auth0/auth0-react';

function FolderControlsMobile({
  anchorEl,
  setAnchorEl,
  handleDeleteFolder,
  setCollaboratorsOpen,
  // handleEditFolderName,
}) {

  const { isAuthenticated } = useAuth0();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >

      {/* <MenuItem 
        onClick={(e) => { 
          gtmTrackButtonClick(e, 'edit_folderName');
          handleEditFolderName();
          handleMenuClose(); 
        }}
      >
        Edit Folder Name
      </MenuItem> */}
      <MenuItem 
        onClick={() => { 
          setCollaboratorsOpen(true);
          handleMenuClose(); 
        }}
      >
        <GroupOutlinedIcon sx={{ marginRight: '1rem' }} /> Collaborators
      </MenuItem>
      {
        isAuthenticated && (
          <MenuItem 
            onClick={(e) => { 
              handleDeleteFolder();
              handleMenuClose(); 
            }}
          >
            <DeleteForeverOutlined sx={{ color: 'red', marginRight: '1rem'}} /> Delete Folder
          </MenuItem>
        )
      }
    </Menu>
  )
}

export default FolderControlsMobile