import React, { useEffect, useMemo } from 'react'
import ContentArea from '../../components/ContentArea';
import { DrawerHeader, Main } from '../../styled';
import { useAppContext } from '../LiveDemo/context';
import FoldersContainer from './FoldersContainer';
import { useParams } from 'react-router-dom';

function FoldersDemo({ drawerOpen, drawerWidth }) {

  const params = useParams();
  const paramsFolderId = parseInt(params?.folderId);
  const paramsMessageId = parseInt(params?.messageId);

  const { setState } = useAppContext();

  useEffect(() => {

    if(paramsFolderId === 1) {
      setState({ run: true, tourActive: true, stepIndex: 2 });
      return;
    };

    if(paramsMessageId === 1){
      setState({ run: true, tourActive: true, stepIndex: 4 });
      return;
    };

    if(!paramsFolderId && !paramsMessageId){
      setState({ run: true, tourActive: true, stepIndex: 1 });
    };

  }, [paramsFolderId, paramsMessageId, setState]); 

  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<FoldersContainer />} />
    </Main>
  );
}

export default FoldersDemo;