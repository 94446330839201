import React, { useMemo, useState } from 'react'
import { 
  Grid, 
  IconButton, 
  Tooltip, 
  Typography 
} from '@mui/material';
import { 
  DocumentListGrid, 
  FileListGrid, 
  InnerGridBackgroundContainer 
} from './styled';
import FolderListItem from '../../components/FolderListItem';
import DocumentListItem from '../../components/DocumentListItem';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@emotion/react';
import FolderControlsMobile from '../../components/FolderControlsMobile';
import FolderCollaboratorsDialog from '../../components/FolderControls/FolderCollaboratorsDialog';
import AddFolderButton from '../../containers/DocumentManager/AddFolderButton';
import DocDetailView from '../../containers/DocumentManager/DocDetailView';
import DemoFolderControlsMobile from './DemoFolderControlsMobile';

function DemoFilesMobile({
  selectedFolder,
  setSelectedFolder,
  filteredDocs,
  ownedMessageFolders,
  sharedMessageFolders,
  managerView,
  selectedFileData,
  setSelectedFileData,
  setManagerView,
  handleDeleteFolder
}) {

  const theme = useTheme();
  const folderColaborators = [];
  const collaborators = [];

  const [editDoc, setEditDoc] = useState(false);
  const [documentContent, setDocumentContent] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [documentFolderId, setDocumentFolderId] = useState(null);
  const [collaboratorsOpen, setCollaboratorsOpen] = useState(false);
  const [folderCollaboratorsOpen, setFolderCollaboratorsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [docCollaboratorChats, setDocCollaboratorChats] = useState([]);
  const [folderCollaborators, setFolderCollaborators] = useState([]);

  const ownedFolderIds = ['1'];
  
  const userIsOwner = () => {
    if(selectedFileData?.ownerId !== true){
      return false;
    };

    return true;
  };

  const userCanEdit = () => {
    return true;
  };

  const truncateText = (text) => {
    if(text){
      const maxLength = 8;
      if (text?.length <= maxLength) {
        return text;
      }
      return text.slice(0, maxLength) + '...';
    } else {
      return 'All';
    }
  };

  useMemo(() => {

    if(selectedFileData){
      setDocumentContent(selectedFileData?.content);
      setDocumentName(selectedFileData?.docName);
      setDocumentFolderId(selectedFileData?.folderId);
      setDocCollaboratorChats(selectedFileData?.collabChats);
    };

  }, [selectedFileData]);

  const handleManagerView = () => {
    switch(managerView){
      case 'Folders':
        return (
          <FileListGrid 
            container 
            sx={{paddingRight: '1rem'}}
          >
            <InnerGridBackgroundContainer>
              <div id={'demo-folder-list'}>
                <AddFolderButton />
                <FolderListItem
                  folderName={'All'}
                  folderId={0}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder} 
                  setManagerView={setManagerView}
                />
                {
                  ownedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={false}
                      selectedFolder={selectedFolder}
                      setSelectedFolder={setSelectedFolder} 
                      setManagerView={setManagerView}
                    />
                  ))
                }
                {
                  sharedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={true}
                      selectedFolder={selectedFolder}
                      setSelectedFolder={setSelectedFolder} 
                      setManagerView={setManagerView}
                    />
                  ))
                }
              </div>
            </InnerGridBackgroundContainer>
          </FileListGrid>
        )
      case 'Files':
        return (
          <DocumentListGrid container>
            <InnerGridBackgroundContainer>
              <Grid 
                sx={{
                  display: 'flex', 
                  alignItems: 'center', 
                  flex: '1', 
                  width: '100%', 
                  justifyContent: 'space-between',
                  maxHeight: '5rem'
                }}
              >
                <IconButton 
                  sx={{
                    marginTop: '0.5rem', 
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    backgroundColor: 'lightgray'
                  }}
                  onClick={() => setManagerView('Folders')}
                >
                  <ArrowBackOutlinedIcon  
                    sx={{
                      color: 'white', 
                      fontSize: '2rem',
                    }} 
                  />
                </IconButton>
                <Grid
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Tooltip title={selectedFolder?.name}>
                    <Typography
                      variant='h4' 
                      sx={{
                        color: 'gray',
                      }}
                    >
                      {truncateText(selectedFolder?.name)}
                    </Typography>
                  </Tooltip>
                  <IconButton
                    id={'demo-folder-controls'}
                    sx={{
                      margin: '1rem',
                      border: `1px solid ${theme.palette.primary.lt}`
                    }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <MoreVertIcon sx={{ color: 'gray' }} />
                  </IconButton>
                </Grid>
                <DemoFolderControlsMobile 
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  // handleEditFolderName={handleEditFolderName}
                  handleDeleteFolder={handleDeleteFolder}
                  setCollaboratorsOpen={setFolderCollaboratorsOpen}
                  selectedFolder={selectedFolder}
                />
                <FolderCollaboratorsDialog
                  open={folderCollaboratorsOpen}
                  collaborators={folderColaborators}
                  setCollaboratorsOpen={setFolderCollaboratorsOpen}
                  selectedFolderData={selectedFolder}
                />
              </Grid>
              <div id={'demo-folder-document-list'}>
                {
                  filteredDocs?.map((message) => (
                    <DocumentListItem 
                      messageData={message} 
                      setSelectedFileData={setSelectedFileData}
                      setManagerView={setManagerView}
                      isMobile={true}
                    />
                  ))
                }
              </div>
            </InnerGridBackgroundContainer>
          </DocumentListGrid>
        )
      case 'Document':
        return (
          <DocDetailView 
            setManagerView={setManagerView}
            setEditDoc={setEditDoc}
            editDoc={editDoc}
            documentFolderId={documentFolderId}
            setDocumentFolderId={setDocumentFolderId}
            userIsOwner={userIsOwner}
            documentName={documentName}
            setDocumentName={setDocumentName}
            setCollaboratorsOpen={setCollaboratorsOpen}
            collaborators={collaborators}
            collaboratorsOpen={collaboratorsOpen}
            selectedFileData={selectedFileData}
            documentContent={documentContent}
            setDocumentContent={setDocumentContent}
            docCollaboratorChats={docCollaboratorChats}
            documentId={selectedFileData?._id}
            folderCollaborators={folderCollaborators}
            userCanEdit={userCanEdit}
          />
        )
      default: return (
        <FileListGrid container>
          <InnerGridBackgroundContainer>
            <FolderListItem
              folderName={'All'}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
            {
              ownedMessageFolders?.map((folder) => (
                <FolderListItem 
                  folderName={'All'}
                  folderId={0}
                  selectedFolder={folder}
                  setSelectedFolder={setSelectedFolder} 
                  setManagerView={setManagerView} 
                />
              ))
            }
          </InnerGridBackgroundContainer>
        </FileListGrid>
      )
    }
  }

  return (
    <>
      {
        handleManagerView()
      }
    </>
  )
}

export default DemoFilesMobile;