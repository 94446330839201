import React, { 
  useEffect, 
  useMemo, 
  useState 
} from 'react'
import { 
  CollabButtonGrid,
  DocumentContentGrid, 
  InnerGridBackgroundContainer, 
  ParentGrid 
} from './styled';
import { 
  Button, 
  Grid, 
  Typography 
} from '@mui/material';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import gtmTrackButtonClick from '../../utils/gtmTrackButtonClick';
import { useDispatch, useSelector } from 'react-redux';
import { updateFolderName } from '../../actions/documentActions';
import FolderCollaboratorsDialog from './FolderCollaboratorsDialog';
import collaboratorsUserInfo from '../../utils/collaboratorsUserInfo';
import { augmentSharedWithCollaboratorInfo } from '../../containers/DocumentManager/helpers';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
import DefaultButtonGroup from './DefaultButtonGroup';
import EditFolderButtonGroup from './EditFolderButtonGroup';
import MoveBulkDocsButtonGroup from './MoveBulkDocsButtonGroup';

function FolderControls({ 
  folderCollaborators,
  setFolderCollaborators,
  isMobile, 
  selectedFolder, 
  handleDeleteFolder,
  ownedFolderIds,
  setMoveBulkDocuments,
  moveBulkDocuments,
  handleMoveBulkDocuments,
  folderToMoveDocsTo,
  setFolderToMoveDocsTo,
  demoActive
}) {

  const dispatch = useDispatch();
  const currentUserInfo = useCurrentUserInfo();

  const allUsersCollaborators = useSelector((state) => state?.collaborators?.retrievedCollaborators);
  const ownedFolders = useSelector((state) => state?.gptChat?.ownedFolders);
  
  const [collaboratorsOpen, setCollaboratorsOpen] = useState(false);
  const [folderName, setFolderName] = useState(selectedFolder?.name);
  const [editName, setEditName] = useState(false);

  const userCanEditName = () => {

    if(selectedFolder?._id === 0){
      return false;
    };

    if(selectedFolder?.ownerId === currentUserInfo?.user_id){
      return true
    }

    if(demoActive){
      return true;
    };

    return false;
  };

  const userCanMoveDoc = () => {

    if(selectedFolder?._id === 0){
      return true;
    };

    if(selectedFolder?.ownerId === currentUserInfo?.user_id){
      return true
    }

    if(demoActive){
      return true;
    };

    return false;
  };

  const userCanDeleteFolder = () => {
    if(selectedFolder?._id === 0){
      return false;
    };

    if(selectedFolder?.ownerId === currentUserInfo?.user_id){
      return true
    };

    if(demoActive){
      return true;
    };

    return false;
  };

  const handleUpdateFolderName = () => {
    setEditName(false);

    dispatch(
      updateFolderName(selectedFolder?._id, folderName, dispatch)
    );
  };

  const cancelUpdateFolderName = () => {
    setFolderName(selectedFolder?.name);
    setEditName(false);
  };

  const handleSave = (updatedCollaborators) => {
    setFolderCollaborators(updatedCollaborators);
  };

  const showButtonGroupConditions = () => {

    if(
      ownedFolderIds?.includes(selectedFolder?._id) ||
      selectedFolder?._id === 0
    ){

      return true;
    };

    return false;
  };

  useMemo(() => {
    setFolderName(selectedFolder?.name);
    setFolderCollaborators(selectedFolder?.sharedWith);
  }, [selectedFolder]);

  useMemo(() => {

    if(allUsersCollaborators && selectedFolder?.sharedWith){

      const updatedFolderData = augmentSharedWithCollaboratorInfo(selectedFolder, allUsersCollaborators);
      setFolderCollaborators(updatedFolderData?.sharedWith);
    };

  }, [allUsersCollaborators, selectedFolder]);

  useEffect(() => {
    if(selectedFolder?.sharedWith?.length){
      collaboratorsUserInfo({
        sharedObjectData: selectedFolder,
        allUsersCollaboratorIds: allUsersCollaborators,
        dispatch
      });
    };

    // eslint-disable-next-line
  }, [selectedFolder?.sharedWith]);

  const RenderButtonGroup = () => {
    if(moveBulkDocuments){
      return (
        <MoveBulkDocsButtonGroup
          userCanMoveDoc={userCanMoveDoc}
          setMoveBulkDocuments={setMoveBulkDocuments}
          selectedFolder={selectedFolder}
          ownedFolders={ownedFolders}
          handleMoveBulkDocuments={handleMoveBulkDocuments}
          folderToMoveDocsTo={folderToMoveDocsTo}
          setFolderToMoveDocsTo={setFolderToMoveDocsTo}
        />
     )
    };

    if(editName){
      return (
        <EditFolderButtonGroup 
          folderName={folderName}
          setFolderName={setFolderName}
          userCanEditName={userCanEditName}
          handleUpdateFolderName={handleUpdateFolderName}
          cancelUpdateFolderName={cancelUpdateFolderName}
        />
      )
    }


    return (
      <DefaultButtonGroup 
        folderName={folderName}
        userCanEditName={userCanEditName}
        setEditName={setEditName}
        userCanMoveDoc={userCanMoveDoc}
        setMoveBulkDocuments={setMoveBulkDocuments}
        handleDeleteFolder={handleDeleteFolder}
        userCanDeleteFolder={userCanDeleteFolder}
      />
    )
  };

  return (
    <ParentGrid container>
      <InnerGridBackgroundContainer 
        container 
        id={'demo-folder-controls'}
      >
        <DocumentContentGrid item>
          {
            showButtonGroupConditions() ? (
              <RenderButtonGroup />
            ) : (
              <Typography variant='h5'>
                {folderName}
              </Typography>
            )
          }
        </DocumentContentGrid>
        <CollabButtonGrid 
          item 
          sx={{ display: selectedFolder?._id === 0 && 'none' }}
        >
          <Grid sx={{ flex: '1' }}>
            <Button
              variant='contained'
              color='secondary'
              onClick={(e) => {
                gtmTrackButtonClick(e, 'click_openCollaborators')
                setCollaboratorsOpen(true)
              }}
              sx={{
                marginRight: '1rem',
                background: 'linear-gradient(to left, #507CE6 0%, #84A8FF 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                borderRadius: '2rem'
              }}
            >
              <>
                <svg width={0} height={0}>
                  <linearGradient id="exampleColors" x1={1} y1={0} x2={1} y2={1} gradientTransform="rotate(45)">
                    <stop offset='0%' stopColor="#507CE6" />
                    <stop offset='50%' stopColor="#84A8FF" />
                    <stop offset='100%' stopColor="#FED602" />
                  </linearGradient>
                </svg>
                <GroupOutlinedIcon sx={{ fill: "url(#exampleColors)", marginRight: '1rem' }} />
              </>
              <Typography>
                Collaborators
              </Typography>
            </Button>
          </Grid>

        </CollabButtonGrid>
        <FolderCollaboratorsDialog
          open={collaboratorsOpen}
          collaborators={folderCollaborators}
          setCollaboratorsOpen={setCollaboratorsOpen}
          onSave={handleSave}
          selectedFolderData={selectedFolder}
          isMobile={isMobile}
        />
      </InnerGridBackgroundContainer>
    </ParentGrid>
  )
}

export default FolderControls;