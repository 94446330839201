import React from 'react'
import ContentArea from '../../components/ContentArea';
import UserDashboard from '../../containers/UserDashboard';
import { DrawerHeader, Main } from '../../styled';


function Dashboard({ drawerOpen, drawerWidth }) {

  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<UserDashboard />} />
    </Main>
  );
}

export default Dashboard;