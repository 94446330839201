import React, { useRef, useEffect } from 'react';
import { 
  MDXEditor as Editor, 
  headingsPlugin,
  listsPlugin, 
  quotePlugin,
  linkPlugin, 
  thematicBreakPlugin,
  UndoRedo, 
  BoldItalicUnderlineToggles, 
  toolbarPlugin, 
  ChangeCodeMirrorLanguage,
  ShowSandpackInfo,
  codeBlockPlugin,
  sandpackPlugin,
  codeMirrorPlugin,
  ConditionalContents,
  InsertCodeBlock,
  InsertSandpack,
  BlockTypeSelect,
  Separator,
  markdownShortcutPlugin,
  useCodeBlockEditorContext,
  ListsToggle,
  InsertTable,
  tablePlugin
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import './styles.css';

function MDXEditor({ content, setContent }) {

  const editorRef = useRef();

  const JavaScriptCodeEditorDescriptor = {
    match: (language, meta) => language === 'javascript' || language === 'jsx',
    priority: 0,
    Editor: (props) => {
      const { setCode } = useCodeBlockEditorContext();
      return (
        <div onKeyDown={(e) => e.nativeEvent.stopImmediatePropagation()}>
          <textarea
            rows={10}
            cols={20}
            defaultValue={props.code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
      );
    }
  };

  const handleChange = (e) => {
    setContent(e);
  };

  const defaultSnippetContent = `
    export default function App() {
      return (
        <div className="App">
          <h1>Hello CodeSandbox</h1>
          <h2>Start editing to see some magic happen!</h2>
        </div>
      );
    }
    `.trim()
  ;

  const simpleSandpackConfig = {
    defaultPreset: 'react',
    presets: [
      {
        label: 'React',
        name: 'react',
        meta: 'live react',
        sandpackTemplate: 'react',
        sandpackTheme: 'light',
        snippetFileName: '/App.js',
        snippetLanguage: 'jsx',
        initialSnippetContent: defaultSnippetContent
      },
    ]
  };

  useEffect(() => {
    if(editorRef?.current){
      editorRef.current?.setMarkdown(content);
    }
  }, [content]);

  return (
    <Editor 
      ref={editorRef}
      markdown={content} 
      className="root" 
      onChange={handleChange}
      plugins={[
        headingsPlugin(), 
        listsPlugin(), 
        quotePlugin(), 
        linkPlugin(),
        thematicBreakPlugin(),
        markdownShortcutPlugin(),
        codeBlockPlugin({ 
          codeBlockEditorDescriptors: [JavaScriptCodeEditorDescriptor],
          defaultCodeBlockLanguage: 'javascript'
        }),
        sandpackPlugin({ sandpackConfig: simpleSandpackConfig }),
        codeMirrorPlugin({ codeBlockLanguages: { javascript: 'JavaScript', css: 'CSS', jsx: 'Javascript' } }),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              {' '}
              <UndoRedo />
              <BlockTypeSelect />
              <BoldItalicUnderlineToggles />
              <Separator />
              <ListsToggle />
              <Separator />
              <ConditionalContents
                options={[
                    { when: (editor) => editor?.editorType === 'codeblock', contents: () => <ChangeCodeMirrorLanguage /> },
                    { when: (editor) => editor?.editorType === 'sandpack', contents: () => <ShowSandpackInfo /> },
                    { fallback: () => ( <> 
                    <InsertCodeBlock />
                    <InsertSandpack />
                  </>) }
                  ]}
                />
            </>
          )
        })
      ]} 
    />
  )
}

export default MDXEditor