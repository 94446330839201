import { Box, Grid, Paper, TextField, styled } from "@mui/material";

const isFirefox = typeof window !== 'undefined' && navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

const ParentContainer = styled(Grid)(({theme}) => ({
  ...theme.flexBox.justifyAlignCenter,
  flexDirection: 'column',
  maxHeight: '90dvh',
  width: '100%',
  flexWrap: 'nowrap',
}));

const ChatContainer = styled(Box)(({theme}) => ({
  
  display: 'flex',
  flexDirection: 'column',
  height: isFirefox ? '100%' : '-webkit-fill-available',
  overflowY: 'auto',
  borderRadius: '8px',
  width: '100%',
  padding: '1rem 5rem',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: '0'
  }
}));

const ChatInputContainer = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  padding: '1rem'
}));

const CustomTextField = styled(TextField)(({theme}) => ({
  margin: '0',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #d7def8!important',
      borderRadius: '1.5rem',
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.secondary.main}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.secondary.main}`,
    },
    color: `${theme.palette.grays.main}`,
  },
  '& .MuiInputLabel-root': {
    color: `gray`,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: `gray`,
  },
  '& .MuiOutlinedInput-input': {
    color: 'gray',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'gray',
  },
  '& .MuiOutlinedInput-input::placeholder': {
    color: 'gray',
  },
}));

const MessageBox = styled(Paper)(({ theme }) => ({
  padding: '10px',
  margin: '10px 0',
  alignSelf: 'flex-start',
  flexDirection: 'column',
  boxShadow: 'none',
  backgroundColor: 'transparent',
  color: 'white',
  display: 'flex',
  justifyContent: 'flex-start', 
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
}));

const UserMessageBox = styled(MessageBox)(({theme}) => ({
  alignSelf: 'flex-end',
  backgroundColor: '#EEF2FC',
  color: theme.palette.primary.contrastText,
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '-webkit-fill-available',
  borderRadius: '2rem'
}));

const MarkdownRendererGridContainer = styled(Grid)(({theme}) => ({
  flex: '10', 
  padding: '0.5rem', 
  display: 'flex', 
  flexWrap: 'wrap',
  maxWidth: '75dvw',
  overflowX: 'auto',
  overflowY: 'visible',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: '90dvw'
  }
}));

export { 
  ParentContainer, 
  ChatContainer,
  ChatInputContainer,
  CustomTextField,
  UserMessageBox,
  MessageBox,
  MarkdownRendererGridContainer
}