import React, { useState } from 'react'
import { CustomTextField } from './styled';
import { IconButton, InputAdornment } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function DemoInput() {

  const [input, setInput] = useState('');

  return (
    <CustomTextField
      name="subject"
      label="Message Chat GPT"
      variant="outlined"
      multiline
      maxRows={1}
      onFocus={event => {
        event.target.select();
      }}
      fullWidth
      onChange={(e) => setInput(e.target.value)}
      value={input}
      sx={{backgroundColor: '#EEF2FC', borderRadius: '2rem'}}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              color="secondary" 
              sx={{backgroundColor: '#c3c9dd'}}
              onClick={() => {
                setInput('');
              }}
              disabled
            >
              <ArrowUpwardIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default DemoInput;