
export const ADD_COLLABORATOR_REQUEST = 'ADD_COLLABORATOR_REQUEST';
export const ADD_COLLABORATOR_SUCCESS = 'ADD_COLLABORATOR_SUCCESS';
export const ADD_COLLABORATOR_FAIL = 'ADD_COLLABORATOR_FAIL';

export const REMOVE_COLLABORATOR_REQUEST = 'REMOVE_COLLABORATOR_REQUEST';
export const REMOVE_COLLABORATOR_SUCCESS = 'REMOVE_COLLABORATOR_SUCCESS';
export const REMOVE_COLLABORATOR_FAIL = 'REMOVE_COLLABORATOR_FAIL';

export const COLLABORATOR_USER_INFO_REQUEST = 'COLLABORATOR_USER_INFO_REQUEST';
export const COLLABORATOR_USER_INFO_SUCCESS = 'COLLABORATOR_USER_INFO_SUCCESS';
export const COLLABORATOR_USER_INFO_FAIL = 'COLLABORATOR_USER_INFO_FAIL';

export const UPDATE_COLLABORATORS_REQUEST = 'UPDATE_COLLABORATORS_REQUEST';
export const UPDATE_COLLABORATORS_SUCCESS = 'UPDATE_COLLABORATORS_SUCCESS';
export const UPDATE_COLLABORATORS_FAIL = 'UPDATE_COLLABORATORS_FAIL';

export const REMOVE_FILE_ID_FROM_USER_REQUEST = 'REMOVE_FILE_ID_FROM_USER_REQUEST';
export const REMOVE_FILE_ID_FROM_USER_SUCCESS = 'REMOVE_FILE_ID_FROM_USER_SUCCESS';
export const REMOVE_FILE_ID_FROM_USER_FAIL = 'REMOVE_FILE_ID_FROM_USER_FAIL';

export const STORE_TEMP_INVITE_DATA = 'STORE_TEMP_INVITE_DATA';
export const CLEAR_TEMP_INVITE_DATA = 'CLEAR_TEMP_INVITE_DATA';
export const INVITE_DELIVER_FAIL = 'INVITE_DELIVER_FAIL';

export const REMOVE_INVITE_REQUEST = 'REMOVE_INVITE_REQUEST';
export const REMOVE_INVITE_SUCCESS = 'REMOVE_INVITE_SUCCESS';
export const REMOVE_INVITE_FAIL = 'REMOVE_INVITE_FAIL';

// CHAT CONSTS

export const SEND_CHAT_REQUEST = 'SEND_CHAT_REQUEST';
export const SEND_CHAT_SUCCESS = 'SEND_CHAT_SUCCESS';
export const SEND_CHAT_FAIL = 'SEND_CHAT_FAIL';

export const EDIT_CHAT_REQUEST = 'EDIT_CHAT_REQUEST';
export const EDIT_CHAT_SUCCESS = 'EDIT_CHAT_SUCCESS';
export const EDIT_CHAT_FAIL = 'EDIT_CHAT_FAIL';

export const DELETE_CHAT_REQUEST = 'DELETE_CHAT_REQUEST';
export const DELETE_CHAT_SUCCESS = 'DELETE_CHAT_SUCCESS';
export const DELETE_CHAT_FAIL = 'DELETE_CHAT_FAIL';

// FOLDER CONSTS

export const SEND_FOLDER_COLLAB_INVITE_REQUEST = 'SENDFOLDER__COLLAB_INVITE_REQUEST';
export const SEND_FOLDER_COLLAB_INVITE_SUCCESS = 'SENDFOLDER__COLLAB_INVITE_SUCCESS';
export const SEND_FOLDER_COLLAB_INVITE_ERROR = 'SENDFOLDER__COLLAB_INVITE_ERROR';

export const REMOVE_FOLDER_COLLABORATOR_REQUEST = 'REMOVE_FOLDER_COLLABORATOR_REQUEST';
export const REMOVE_FOLDER_COLLABORATOR_SUCCESS = 'REMOVE_FOLDER_COLLABORATOR_SUCCESS';
export const REMOVE_FOLDER_COLLABORATOR_FAIL = 'REMOVE_FOLDER_COLLABORATOR_FAIL';

export const UPDATE_FOLDER_COLLABORATORS_REQUEST = 'UPDATE_FOLDER_COLLABORATORS_REQUEST';
export const UPDATE_FOLDER_COLLABORATORS_SUCCESS = 'UPDATE_FOLDER_COLLABORATORS_SUCCESS';
export const UPDATE_FOLDER_COLLABORATORS_FAIL = 'UPDATE_FOLDER_COLLABORATORS_FAIL';

export const REMOVE_FOLDER_INVITE_REQUEST = 'REMOVE_FOLDER_INVITE_REQUEST';
export const REMOVE_FOLDER_INVITE_SUCCESS = 'REMOVE_FOLDER_INVITE_SUCCESS';
export const REMOVE_FOLDER_INVITE_FAIL = 'REMOVE_FOLDER_INVITE_FAIL';