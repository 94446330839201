import React, { useEffect, useMemo, useState } from 'react'
import { 
  Button, 
  ListItem, 
  Typography,
} from '@mui/material';
import { 
  DocumentListGrid, 
  FileListGrid, 
  InnerGridBackgroundContainer 
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import FolderListItem from '../../components/FolderListItem';
import DocumentListItem from '../../components/DocumentListItem';
import { 
  MESSAGES_COLLECTION_SAVE_SUCCESS, 
  UPDATE_TRIAL_GPT_MESSAGE_SUCCESS 
} from '../../consts/gptChatConstants';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { augmentSharedWithCollaboratorInfo } from './helpers';
import { updateGptMessage } from '../../actions/gptChatActions';
import DocDetailView from './DocDetailView';
import FolderControls from '../../components/FolderControls';
import collaboratorsUserInfo from '../../utils/collaboratorsUserInfo';
import AddFolderButton from './AddFolderButton';
import { documentFolderUpdate } from '../../actions/documentActions';

function FilesViewDesktop({
  selectedFolder,
  setSelectedFolder,
  filteredDocs,
  ownedMessageFolders,
  sharedMessageFolders,
  managerView,
  selectedFileData,
  setSelectedFileData,
  setManagerView,
  handleDeleteFile,
  handleDeleteFolder
}) {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 
		isAuthenticated, 
		loginWithRedirect
	} = useAuth0();

  const userId = useSelector((state) => state?.userData?.loginInfo?.user_id);
  const allUsersCollaborators = useSelector((state) => state?.collaborators?.retrievedCollaborators);

  const [editDoc, setEditDoc] = useState(false);
  const [documentContent, setDocumentContent] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [documentFolderId, setDocumentFolderId] = useState(null);
  const [documentChatHistory, setDocumentChatHistory] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [collaboratorsOpen, setCollaboratorsOpen] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [folderCollaborators, setFolderCollaborators] = useState([]);
  const [docCollaboratorChats, setDocCollaboratorChats] = useState([]);
  const [moveBulkDocuments, setMoveBulkDocuments] = useState(false);
  const [docsToMove, setDocsToMove] = useState([]);
  const [folderToMoveDocsTo, setFolderToMoveDocsTo] = useState(null);

  const ownedFolderIds = ownedMessageFolders?.map((folder) => folder?._id);
  
  const handleSaveCollaborators = (updatedCollaborators) => {
    setCollaborators(updatedCollaborators);
  };

  const handleContinueConversation = () => {
    dispatch({ 
      type: MESSAGES_COLLECTION_SAVE_SUCCESS, 
      payload: selectedFileData?.savedMessageHistory
    });

    if(isAuthenticated){
      navigate('/ai-chat');
    } else {
      navigate('/');
    };
  };

  const handleMoveBulkDocuments = () => {
    dispatch(
      documentFolderUpdate({ 
        docsToMove: docsToMove, 
        updatedFolderId: folderToMoveDocsTo?._id 
      }),
      dispatch
    )
    setMoveBulkDocuments(false);
  };
  
  const handleUpdateContent = async() => {

    if(isAuthenticated){
    
      dispatch(
        updateGptMessage(
          {
            content: documentContent, 
            folderId: documentFolderId, 
            docName: documentName, 
            messageId: selectedFileData?._id
          }
        )
      );

      setEditDoc(false);
       
    } else {
      dispatch({
        type: UPDATE_TRIAL_GPT_MESSAGE_SUCCESS,
        payload: {
          content: documentContent,
          folderId: documentFolderId,
          docName: documentName,
          savedMessageHistory: documentChatHistory,
          _id: documentId
        }
      });

      setEditDoc(false)
    };
  };
  
  const userIsOwner = () => {
    if(selectedFileData?.ownerId !== userId){
      return false;
    };

    return true;
  };

  const userCanEdit = () => {
    const userInMessageObject = selectedFileData?.sharedWith?.filter((user) => user?.user_id === userId);
    if(userInMessageObject){
      const userPermission = userInMessageObject[0]?.permission;

      if(userPermission === 'canEdit'){
        return true;
      };
    };

    return false;
  };

  useMemo(() => {

    if(selectedFileData){
      setDocumentContent(selectedFileData?.content);
      setDocumentName(selectedFileData?.docName);
      setDocumentFolderId(selectedFileData?.folderId);
      setDocumentChatHistory(selectedFileData?.savedMessageHistory);
      setDocumentId(selectedFileData?._id);
      setDocCollaboratorChats(selectedFileData?.collabChats);
    };

  }, [selectedFileData]);

  // Whenever new collaborators are added,
  // this adds enriched user data for display from the
  // collaboratorsUserInfo() method result
  useMemo(() => {

    if(allUsersCollaborators && selectedFileData?.sharedWith){

      const updatedFileData = augmentSharedWithCollaboratorInfo(selectedFileData, allUsersCollaborators);
      setCollaborators(updatedFileData?.sharedWith);
    };

  }, [allUsersCollaborators, selectedFileData]);

  // Folder specific
  useMemo(() => {

    if(allUsersCollaborators && selectedFolder?.sharedWith){

      const updatedFolderData = augmentSharedWithCollaboratorInfo(selectedFolder, allUsersCollaborators);
      setFolderCollaborators(updatedFolderData?.sharedWith);
    };

    setMoveBulkDocuments(false);
    setFolderToMoveDocsTo(null);
    setDocsToMove([]);

  }, [allUsersCollaborators, selectedFolder]);

  // Gets any collaborators full userInfo from database for 
  // proper display of data about that user
  useEffect(() => {

    if(selectedFileData?.sharedWith?.length){
      collaboratorsUserInfo({
        sharedObjectData: selectedFileData,
        allUsersCollaboratorIds: allUsersCollaborators,
        dispatch
      });
    };

    // eslint-disable-next-line
  }, [selectedFileData?.sharedWith]);
  

  return (
    <>
      {
        managerView === 'Folders' || managerView === 'Files' ? (
          <>
            <FileListGrid container>
              <InnerGridBackgroundContainer>
                {
                  !isAuthenticated && (
                    <ListItem sx={{display: 'flex', justifyContent: 'center'}}>
                      <Button 
                        variant='contained' 
                        color='primary'
                        onClick={() => loginWithRedirect()}
                      >
                        <Typography sx={{color: 'white'}}>
                          <b>Login</b> To Keep Saved Files
                        </Typography>
                      </Button>
                    </ListItem>
                  )
                }
                <AddFolderButton />
                <FolderListItem
                  folderName={'All'}
                  folderId={0}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder} 
                  setManagerView={setManagerView}
                />
                {
                  ownedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={false}
                      selectedFolder={selectedFolder}
                      setSelectedFolder={setSelectedFolder} 
                      setManagerView={setManagerView}
                    />
                  ))
                }
                {
                  sharedMessageFolders?.map((folder) => (
                    <FolderListItem 
                      key={folder?._id}
                      folderName={folder?.name}
                      folderId={folder?._id}
                      folderData={folder}
                      shared={true}
                      selectedFolder={selectedFolder}
                      setSelectedFolder={setSelectedFolder} 
                      setManagerView={setManagerView}
                    />
                  ))
                }
              </InnerGridBackgroundContainer>
            </FileListGrid>
            <DocumentListGrid container >
              <InnerGridBackgroundContainer sx={{ justifyContent: 'flex-start' }} >
                {
                  selectedFolder && (
                    <FolderControls 
                      folderCollaborators={folderCollaborators}
                      setFolderCollaborators={setFolderCollaborators}
                      selectedFolder={selectedFolder}
                      handleDeleteFolder={handleDeleteFolder}
                      ownedFolderIds={ownedFolderIds}
                      setMoveBulkDocuments={setMoveBulkDocuments}
                      moveBulkDocuments={moveBulkDocuments}
                      handleMoveBulkDocuments={handleMoveBulkDocuments}
                      setSelectedFolder={setSelectedFolder}
                      folderToMoveDocsTo={folderToMoveDocsTo}
                      setFolderToMoveDocsTo={setFolderToMoveDocsTo}
                    />
                  )
                }
                {
                  filteredDocs?.map((message, index) => (
                    <DocumentListItem 
                      key={index}
                      messageData={message} 
                      setSelectedFileData={setSelectedFileData}
                      setManagerView={setManagerView}
                      moveBulkDocuments={moveBulkDocuments}
                      setDocsToMove={setDocsToMove}
                      docsToMove={docsToMove}
                    />
                  ))
                }
              </InnerGridBackgroundContainer>
            </DocumentListGrid>
          </>
        ) : (
          <DocDetailView 
            setManagerView={setManagerView}
            setEditDoc={setEditDoc}
            editDoc={editDoc}
            documentFolderId={documentFolderId}
            setDocumentFolderId={setDocumentFolderId}
            userIsOwner={userIsOwner}
            userCanEdit={userCanEdit}
            documentName={documentName}
            setDocumentName={setDocumentName}
            setCollaboratorsOpen={setCollaboratorsOpen}
            collaborators={collaborators}
            collaboratorsOpen={collaboratorsOpen}
            handleSave={handleSaveCollaborators}
            selectedFileData={selectedFileData}
            handleUpdateContent={handleUpdateContent}
            handleContinueConversation={handleContinueConversation}
            handleDeleteFile={handleDeleteFile}
            documentContent={documentContent}
            setDocumentContent={setDocumentContent}
            docCollaboratorChats={docCollaboratorChats}
            documentId={selectedFileData?._id}
            folderCollaborators={folderCollaborators}
          />
        )
      }
    </>
  )
}

export default FilesViewDesktop;