import { 
  Grid, 
  Paper, 
  styled 
} from "@mui/material";

const MainContentArea = styled(Paper)(({ theme }) => ({
  height: '90dvh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent'
}));

const MainContentAreaChildGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '100%',
}));

export { 
  MainContentArea,
  MainContentAreaChildGrid,
};