import React, { useState } from 'react';
import { FileListGrid, InnerGridBackgroundContainer } from './styled';
import { 
  Avatar, 
  AvatarGroup, 
  Badge, 
  Button, 
  Fab, 
  Grid, 
  IconButton, 
  Menu, 
  MenuItem, 
  Tooltip, 
  Typography, 
  useMediaQuery 
} from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MDXEditor from '../../components/MDXEditor';
import CollaboratorsDialog from './CollabDialog';
import EditDocDetails from './EditDocDetails';
import EditDocControls from './EditDocControls';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MarkdownRenderer from './MarkdownRenderer';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ConstructionIcon from '@mui/icons-material/Construction';
import ForumIcon from '@mui/icons-material/Forum';
import CollaboratorChat from '../../components/CollaboratorChat';
import { useAuth0 } from '@auth0/auth0-react';
import gtmTrackButtonClick from '../../utils/gtmTrackButtonClick';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { mergeCollaborators } from './helpers';

function DocDetailView({
  setManagerView,
  setEditDoc,
  editDoc,
  documentFolderId,
  setDocumentFolderId,
  userIsOwner,
  userCanEdit,
  documentName,
  setDocumentName,
  setCollaboratorsOpen,
  collaborators,
  collaboratorsOpen,
  handleSave,
  selectedFileData,
  handleUpdateContent,
  handleContinueConversation,
  handleDeleteFile,
  documentContent,
  setDocumentContent,
  docCollaboratorChats,
  documentId,
  folderCollaborators
}) {

  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const ownedMessageFolders = useSelector((state) => state?.gptChat?.ownedFolders);

  const [anchorEl, setAnchorEl] = useState(null);
  const [collabChatOpen, setCollabChatOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const truncateText = (text) => {
    const maxLength = isMobile ? 10 : 125;
    if (text?.length <= maxLength) {
      return text;
    };
    
    return text?.slice(0, maxLength) + '...';
  };

  const EditDocumentGrantedMobile = () => {
    if(userIsOwner() || userCanEdit()){
      return (
        <MenuItem 
          onClick={() => { 
            setEditDoc(true); handleMenuClose(); 
          }}
          disabled={!isAuthenticated}
        >
          <EditIcon sx={{ marginRight: '1rem' }} /> Edit Document
        </MenuItem>
      )
    };

    return null;
  };

  const combinedUniqueCollaborators = mergeCollaborators(collaborators, folderCollaborators);

  const MobileDocOptions = () => {
    return (
      <>
        <Fab 
          color="secondary" 
          aria-label="menu" 
          onClick={handleMenuOpen} 
          variant='extended'
          sx={{ 
            position: 'fixed', 
            top: 10, 
            right: 10,
            background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`,
            color: 'white',
            zIndex: '1250'
          }}
        >
          <ConstructionIcon sx={{ fontSize: '2rem'}} />
          <Typography variant='h6' sx={{padding: '1rem'}}>
            Options
          </Typography>
        </Fab>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem 
            onClick={(e) => { 
              gtmTrackButtonClick(e, 'click_openCollaborators');
              setCollaboratorsOpen(true); 
              handleMenuClose(); 
            }}
            disabled={!isAuthenticated}
          >
            <GroupOutlinedIcon sx={{ marginRight: '1rem' }} /> Collaborators
          </MenuItem>
          <EditDocumentGrantedMobile />
          <MenuItem 
            onClick={() => { 
              handleContinueConversation(); 
              handleMenuClose(); 
            }}
            disabled={!isAuthenticated}
          >
            <AutoAwesomeOutlinedIcon sx={{ marginRight: '1rem' }} /> Continue Conversation
          </MenuItem>
          <MenuItem 
            onClick={(e) => { 
              gtmTrackButtonClick(e, 'click_openChat');
              setCollabChatOpen(true); 
              handleMenuClose(); 
            }}
            disabled={!isAuthenticated}
          >
            <ForumIcon sx={{ marginRight: '1rem' }} /> Collab Chat
          </MenuItem>
        </Menu>
      </>
    )
  };

  return (
    <FileListGrid container sx={{paddingRight: '1rem'}}>
      <InnerGridBackgroundContainer sx={{flexDirection: 'column'}}>
        <Grid
          id={'demo-document-header'}
          sx={{
            display: 'flex', 
            alignItems: 'center', 
            flex: '1', 
            width: '100%', 
            justifyContent: 'space-between', 
            maxHeight: '6rem',
          }}
        >
          {
            (!editDoc) && (
              <IconButton 
                sx={{padding: !isMobile && '1rem', margin: '1rem'}}
                onClick={() => {
                  setManagerView('Files');
                  setEditDoc(false);
    
                  if(isAuthenticated){
                    navigate('/folders');
                  } else {
                    navigate('/folders-trial')
                  }
                }}
              >
                <ArrowBackOutlinedIcon  
                  sx={{color: 'black', fontSize: isMobile ? '2rem' : '3rem'}} 
                />
              </IconButton>
            )
          }
          <Grid 
            sx={{
              display: 'flex', 
              paddingRight: !isMobile && '2rem', 
              justifyContent: 'space-evenly', 
              alignItems: 'center', 
              minWidth: isMobile ? '0' : '35rem', 
              width: '100%',
            }}
          >
            {
              editDoc ? (
                <EditDocDetails 
                  documentFolderId={documentFolderId}
                  setDocumentFolderId={setDocumentFolderId}
                  ownedMessageFolders={ownedMessageFolders}
                  userIsOwner={userIsOwner}
                  documentName={documentName}
                  setDocumentName={setDocumentName}
                  isMobile={isMobile}
                />
              ) : (
                <Grid
                  sx={{
                    flex: '5',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    padding: isMobile && '1rem'
                  }}
                >
                  <Tooltip title={documentName}>
                    <Typography
                      variant='h4' 
                      sx={{ color: 'black' }}
                    >
                      {truncateText(documentName)}
                    </Typography>
                  </Tooltip>
                  <AvatarGroup 
                    max={4} 
                    onClick={() => setCollaboratorsOpen(true)} 
                    sx={{
                      cursor: 'pointer', 
                      marginRight: '1rem',
                      display: isMobile && 'none'
                    }}
                  >
                    {
                      combinedUniqueCollaborators?.map((user, index) => (
                        <Avatar key={index} alt={user?.name} src={user?.picture} />
                      ))
                    }
                  </AvatarGroup>
                  <CollaboratorsDialog
                    open={collaboratorsOpen}
                    collaborators={collaborators}
                    folderCollaborators={folderCollaborators}
                    setCollaboratorsOpen={setCollaboratorsOpen}
                    onSave={handleSave}
                    selectedFileData={selectedFileData}
                    isMobile={isMobile}
                  />
                </Grid>
              )
            }
            {!isMobile && (
              editDoc ? (
                <EditDocControls
                  handleUpdateContent={handleUpdateContent}
                  setEditDoc={setEditDoc}
                />
              ) : (
                <Grid sx={{flex: '1', display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={(e) => {
                      gtmTrackButtonClick(e, 'click_openCollaborators')
                      setCollaboratorsOpen(true)
                    }}
                    sx={{
                      marginRight: '1rem',
                      background: 'linear-gradient(to left, #507CE6 0%, #84A8FF 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      borderRadius: '2rem'
                    }}
                  >
                    <>
                      <svg width={0} height={0}>
                        <linearGradient id="exampleColors" x1={1} y1={0} x2={1} y2={1} gradientTransform="rotate(45)">
                          <stop offset='0%' stopColor="#507CE6" />
                          <stop offset='50%' stopColor="#84A8FF" />
                          <stop offset='100%' stopColor="#FED602" />
                        </linearGradient>
                      </svg>
                      <GroupOutlinedIcon sx={{ fill: "url(#exampleColors)", marginRight: '1rem' }} />
                    </>
                    <Typography>
                      Collaborators
                    </Typography>
                  </Button>
                    
                  {
                    (userIsOwner() || userCanEdit()) && (
                      <Tooltip title="Edit Document">
                        <IconButton 
                          sx={{
                            background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`,
                            marginRight: '1rem'
                          }}
                          onClick={(e) => {
                            gtmTrackButtonClick(e, 'click_editFile')
                            setEditDoc(true)
                          }}
                        >
                          <EditIcon sx={{color: 'white'}} />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                  <Tooltip title="Continue Conversation">
                    <IconButton 
                      sx={{
                        background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)` ,
                        marginRight: '1rem'
                      }}
                      onClick={() => handleContinueConversation()}
                    >
                      <AutoAwesomeOutlinedIcon sx={{color: 'white'}} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Collaborator Chat">
                    <IconButton 
                      sx={{
                        background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)` 
                      }}
                      onClick={(e) => {
                        gtmTrackButtonClick(e, 'click_openChat')
                        setCollabChatOpen(true)
                      }}
                    >
                      <Badge 
                        badgeContent={selectedFileData?.collabChats?.length} 
                        color='secondary'
                        sx={{
                          '& span': {
                            border: '1px solid gray'
                          }
                        }}
                      >
                        <ForumIcon sx={{color: 'white'}} />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        <div style={{flex: '5'}}>
          {
            editDoc ? (
              <Grid sx={{width: '-webkit-fill-available', padding: '0 1rem'}}>
                <MDXEditor content={documentContent} setContent={setDocumentContent}/>
              </Grid>
            ) : (
              <Typography 
                variant='h6' 
                sx={{
                  textWrap: 'wrap',
                  color: 'black',
                  padding: '2rem',
                  paddingTop: '0',
                }}
              >
                <MarkdownRenderer content={documentContent} />
              </Typography>
            )
          }
          {
            collabChatOpen && (
              <CollaboratorChat 
                isMobile={isMobile} 
                setCollabChatOpen={setCollabChatOpen}
                userIsOwner={userIsOwner}
                docCollaboratorChats={docCollaboratorChats}
                documentId={documentId}
              />
            )
          }
        </div>
        <Grid 
          sx={{
            display: userIsOwner() ? 'flex' : 'none', 
            width: '100%', 
            justifyContent: 'flex-end', 
            padding: '1rem'
          }}
        >
          {
            (isMobile && editDoc) && (
              <Grid 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center'
                }}
              >
                <Button
                  sx={{
                    background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`,
                    color: 'white'
                  }}
                  onClick={() => handleUpdateContent()}
                >
                  Save Changes
                </Button>
                <Button
                  variant='outlined'
                  sx={{
                    color: 'gray',
                    border: '1px solid gray'
                  }}
                  onClick={() => setEditDoc(false)}
                >
                  Cancel
                </Button>
              </Grid>
            )
          }
          {
            !editDoc && (
              <Button
                variant='outlined'
                sx={{
                  color: 'red',
                  border: '1px solid red'
                }}
                onClick={handleDeleteFile}
              >
                Delete File
              </Button>
            )
          }
        </Grid>

        {(isMobile && !editDoc) && (
          <MobileDocOptions />
        )}
      </InnerGridBackgroundContainer>
    </FileListGrid>
  )
};

export default DocDetailView;