import React from 'react';
import ContentArea from '../../components/ContentArea';
import { DrawerHeader, Main } from '../../styled';
import DemoChat from './DemoChat';
import { useAppContext } from '../LiveDemo/context';
import { useMount } from 'react-use';


function AIChatDemo({ drawerOpen, drawerWidth }) {
  
  const { setState } = useAppContext();

  useMount(() => {
    setState({ run: true, tourActive: true, stepIndex: 0 });
  });

  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<DemoChat />} />
    </Main>
  );
}

export default AIChatDemo;