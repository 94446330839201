import React from 'react';
import { 
  Grid, 
  IconButton, 
  Tooltip, 
  useMediaQuery,
  TextField
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SaveIcon from '@mui/icons-material/Save';

function EditFolderButtonGroup({
  folderName,
  userCanEditName,
  setFolderName,
  handleUpdateFolderName,
  cancelUpdateFolderName
}) {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Grid 
      sx={{
        display: 'flex', 
        flex: '1', 
        justifyContent: 'center', 
        alignItems: 'center'
      }}
    >
      <Grid 
        sx={{
          flex: isMobile && '9', 
          height: '100%', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: isMobile ? 'flex-start' : 'center'
        }}
      >
        <TextField
          defaultValue={folderName} 
          onChange={(e) => setFolderName(e.target.value)}
          autoFocus
        />
      </Grid>
      <Grid 
        sx={{
          marginLeft: isMobile && '1rem', 
          display: userCanEditName() ? 'flex' : 'none'
        }}
      >
        <Tooltip title='Save Name'>
          <IconButton 
            size='small'
            sx={{
              marginLeft: isMobile ? '0' : '2rem', 
              border: '1px solid gray'
            }}
            onClick={handleUpdateFolderName}
          >
            <SaveIcon sx={{color: 'gray', fontSize: isMobile && '1rem'}} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid sx={{ marginLeft: '1rem' }}>
        <Tooltip title='Cancel'>
          <IconButton 
            size='small' 
            sx={{ border: '1px solid gray'}}
            onClick={cancelUpdateFolderName}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default EditFolderButtonGroup;