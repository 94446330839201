import React from 'react'
import { MarkdownRendererGridContainer, MessageBox } from './styled';
import { 
  Button, 
  Grid, 
  IconButton, 
  Typography, 
  useTheme,
  // Popover, 
} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DemoUserMessage from './DemoUserMessage';
import MarkdownRenderer from '../../containers/GPTChat/MarkdownRenderer';
// import SavePopout from './SavePopout';

function DemoMessages({
  messages, 
  userInfo,
  handleClick,
  anchorEl,
  // handleClosePopover,
  // addNewFolder,
  // handleClose,
  // userFolders,
  // setAddNewFolder,
  // setSelectedFolder,
  // selectedFolder,
  // handleSaveDoc,
  // handleChange,
}) {

  const theme = useTheme();

  if(messages.length){
    return messages.map((message, index) => {

      if(message.role === 'user'){
        return (
          <DemoUserMessage
            key={index}
            message={message} 
            userInfo={userInfo} 
          />
        )
      } else {
        return (
          <MessageBox key={index}>
            <Grid
              sx={{
                display: 'flex', 
                flex: '1', 
                width: '100%', 
                justifyContent: 'flex-start', 
                alignItems: 'center', 
                padding: '0.5rem',
                maxHeight: '5rem'
              }}
            >
              <IconButton 
                disabled 
                sx={{
                  border: '1px solid lightgray', 
                  padding: '0.75rem',
                  marginRight: '1rem',
                }}
              >
                <>
                  <svg width={0} height={0}>
                    <linearGradient id="exampleColors" x1={1} y1={0} x2={1} y2={1} gradientTransform="rotate(45)">
                      <stop offset='0%' stopColor="#507CE6" />
                      <stop offset='50%' stopColor="#84A8FF" />
                      <stop offset='100%' stopColor="#FED602" />
                    </linearGradient>
                  </svg>
                  <AutoAwesomeIcon sx={{ fill: "url(#exampleColors)" }} />
                </>
              </IconButton>
              <Button
                id={"user-message-demo"}
                sx={{
                  border: `1px solid ${theme.palette.secondary.main}`, 
                  padding: '0.5rem 1rem',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '10rem',
                  borderRadius: '2rem',
                  background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)` 
                }}
                onClick={(e) => handleClick(e, message)}
              >
                <SaveOutlinedIcon 
                  sx={{
                    color: `${theme.palette.secondary.main}`, 
                    fontSize: '2rem',
                    marginRight: '1rem'
                  }} 
                />
                <Typography color={'secondary'} variant='h6'>
                  Save
                </Typography>
              </Button>
              {/* <Popover
                key={index}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <SavePopout 
                  addNewFolder={addNewFolder}
                  handleClose={handleClose}
                  userFolders={userFolders}
                  setAddNewFolder={setAddNewFolder}
                  setSelectedFolder={setSelectedFolder}
                  selectedFolder={selectedFolder}
                  handleSaveDoc={handleSaveDoc}
                  handleChange={handleChange}
                />
              </Popover> */}
            </Grid>
            <MarkdownRendererGridContainer>
              <Typography
                variant='h6' 
                sx={{
                  marginLeft: '0.5rem', 
                  textWrap: 'wrap',
                  color: '#373737',
                  height: '100%'
                }}
              >
                <MarkdownRenderer content={message?.content} />
              </Typography>
            </MarkdownRendererGridContainer>
          </MessageBox>
        )
      }
    })
  };

  return null;
};

export default DemoMessages;