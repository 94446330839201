import React from 'react'
import { 
  FolderIconGrid, 
  FolderNameGrid, 
  InnerGridBackgroundContainer, 
  ParentGrid 
} from './styled';
import { Typography } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import { useNavigate } from 'react-router-dom';

function DashFolderListItem({ folderId, folderName, shared }) {

  const navigate = useNavigate();

  const handleSelectFolder = () => {
    navigate(`/folders/${folderId}`);
  };

  return (
    <ParentGrid container onClick={handleSelectFolder}>
      <InnerGridBackgroundContainer container>
        <FolderIconGrid item>
          {
            shared ? (
              <FolderSharedOutlinedIcon sx={{color: 'gray', fontSize: '2rem'}} />
            ) : (
              <FolderOutlinedIcon sx={{color: 'gray', fontSize: '2rem'}} />
            )
          }
        </FolderIconGrid>
        <FolderNameGrid item>
          <Typography variant='h5' sx={{color: 'gray'}}>
            {folderName}
          </Typography>
        </FolderNameGrid>
      </InnerGridBackgroundContainer>
    </ParentGrid>
  )
}

export default DashFolderListItem;