import axios from "axios";
import { 
  GET_DB_MESSAGES_FAIL, 
  GET_DB_MESSAGES_REQUEST, 
  GET_DB_MESSAGES_SUCCESS, 
  GPT_VERSION_CHANGE,
  SAVE_TRIAL_GPT_MESSAGE_SUCCESS,
  SAVE_GPT_MESSAGE_FAIL, 
  SAVE_GPT_MESSAGE_REQUEST, 
  SAVE_GPT_MESSAGE_SUCCESS,
  DELETE_GPT_MESSAGE_FAIL, 
  DELETE_GPT_MESSAGE_REQUEST, 
  DELETE_GPT_MESSAGE_SUCCESS, 
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAIL,
  SHARE_FOLDER_MESSAGES_REQUEST,
  SHARE_FOLDER_MESSAGES_FAIL
} from "../consts/gptChatConstants";
import getRandomInteger from "../helpers/randomInt";

export const config = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

export const updateGPTVersion = (newVersion) => (dispatch) => {
  dispatch({ type: GPT_VERSION_CHANGE, payload: newVersion});
};

export const dbSavedMessages = ({userId, invitedMessage}) => async(dispatch) => {

  dispatch({ type: GET_DB_MESSAGES_REQUEST });

  try{
    const response = await axios.get(`${process.env.REACT_APP_API_PATH}api/messages/${userId}`);

    const { externalMessages, ownedMessages } = response?.data;

    const externalMessagesIncludesInvitedMessage = externalMessages.filter(
      (message) => message?._id === invitedMessage?._id
    );
    
    if(invitedMessage && !externalMessagesIncludesInvitedMessage.length){
      dispatch({ 
        type: GET_DB_MESSAGES_SUCCESS, 
        payload: {
          externalMessages: (
            externalMessages ? 
            [...externalMessages, invitedMessage] : 
            invitedMessage
          ) ?? [], 
          ownedMessages: ownedMessages ?? [],
        } 
      });
    } else {
      dispatch({ 
        type: GET_DB_MESSAGES_SUCCESS, 
        payload: {
          externalMessages: externalMessages ?? [], 
          ownedMessages: ownedMessages ?? [],
        } 
      });
    }

  } catch (error) {

    dispatch ({ type: GET_DB_MESSAGES_FAIL, payload: error.message })
  }
};

export const saveGPTMessage = (
  userId, 
  {
    content, 
    folderId, 
    docName,
    savedMessageHistory, 
    isAuthenticated
  }
) => async(dispatch) => {

  const path = `${process.env.REACT_APP_API_PATH}api/messages/saveGptMessage`;

  dispatch({ type: SAVE_GPT_MESSAGE_REQUEST  });

  if(isAuthenticated){
    try{

      const response = await axios.post(
        path, 
        {
          userId: userId, 
          content: content,
          folderId: folderId,
          docName: docName,
          savedMessageHistory: savedMessageHistory
        }, 
        config
      );
      
      if(response?.status === 200){
        
        dispatch({ 
          type: SAVE_GPT_MESSAGE_SUCCESS, 
          payload: { 
            content: content, 
            folderId: folderId, 
            docName: docName, 
            _id: response?.data?.newMessage?._id ,
            savedMessageHistory: savedMessageHistory,
            ownerId: response?.data?.newMessage?.ownerId
          } 
        });
      };
  
    } catch (error) {
      dispatch({ type: SAVE_GPT_MESSAGE_FAIL, payload: {error: error.message}});
    };
  } else {
    dispatch({ 
      type: SAVE_TRIAL_GPT_MESSAGE_SUCCESS, 
      payload: { 
        content: content, 
        folderId: 0, 
        docName: docName, 
        _id: getRandomInteger(0, 1000000000000),
        savedMessageHistory: savedMessageHistory
      } 
    });
  };
};

export const updateGptMessage = ({content, folderId, docName, messageId}) => async(dispatch) => {

  dispatch({ type: UPDATE_DOCUMENT_REQUEST });

  try {
    const url = `${process.env.REACT_APP_API_PATH}api/messages/update-document/${messageId}`;
    const response = await axios.put(url, {content: content, folderId: folderId, docName: docName});

    if(response.status === 200){
      dispatch({ type: UPDATE_DOCUMENT_SUCCESS, payload: response?.data?.document});
    };
  } catch(error){
    dispatch({ type: UPDATE_DOCUMENT_FAIL, payload: error.message});
  };
};


export const deleteGPTMessage = (userId, messageId) => async(dispatch) => {

  const path = `${process.env.REACT_APP_API_PATH}api/messages/deleteGptMessage`;

  dispatch({ type: DELETE_GPT_MESSAGE_REQUEST });



  try{
    const response = await axios.post(
      path, 
      {
        userId: userId, 
        messageId: messageId,
      }, 
      config
    );
    
    if(response?.status === 200){
      dispatch({ 
        type: DELETE_GPT_MESSAGE_SUCCESS, 
        payload: { messageId: messageId } 
      });
    };

  } catch (error) {
    dispatch({ type: DELETE_GPT_MESSAGE_FAIL, payload: {error: error.message}});
  };
};
