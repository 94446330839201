import React from 'react'
import { 
  DocumentContentGrid, 
  GoToFileGrid, 
  InnerGridBackgroundContainer, 
  ParentGrid 
} from './styled';
import { 
  Grid, 
  IconButton, 
  Typography, 
  useMediaQuery 
} from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function DashDocListItem({ messageData }) {

  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const stripHtmlTags = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(messageData?.content, 'text/html');
    return doc.body.textContent || "";
  };

  const truncateText = (text) => {
    const maxLength = isMobile ? 70 : 100;
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  const truncateHeaderText = (text) => {
    const maxLength = isMobile ? 15 : 20;
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  }

  const handleSelectFile = () => {
    if(isAuthenticated){
      navigate(`/folders/messages/${messageData?._id}`);
    } else {
      navigate(`/folders-trial/${messageData?._id}`);
    };
  };

  return (
    <ParentGrid container>
      <InnerGridBackgroundContainer container>
        <DocumentContentGrid item>
          <Grid 
            sx={{
              display: 'flex', 
              flex: '1', 
              justifyContent: 'center', 
              alignItems: 'center'
            }}
          >
            <Grid 
              sx={{
                flex: isMobile && '9', 
                height: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: isMobile ? 'flex-start' : 'center'
              }}
            >
              <Typography 
                variant={ isMobile ? 'h6' : 'h5'} 
                sx={{color: 'black'}}
              >
                { truncateHeaderText(messageData?.docName) }
              </Typography>
            </Grid>
          </Grid>
          <Grid sx={{flex: isMobile ? '2' : '1'}}>
            <Typography variant='body1' sx={{color: 'black'}}>
              { truncateText( stripHtmlTags() ) }
            </Typography>
          </Grid>
        </DocumentContentGrid>
        <GoToFileGrid item>
          <IconButton 
            sx={{
              background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`,
              padding: '0'
            }}
            onClick={handleSelectFile}
          >
            <ArrowCircleRightIcon 
              sx={{ 
                fontSize: '3rem',
                color: 'white'
              }}
            />
          </IconButton>
        </GoToFileGrid>
      </InnerGridBackgroundContainer>
    </ParentGrid>
  )
}

export default DashDocListItem;