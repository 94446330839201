import React, { useMemo, useState } from 'react'
import { 
  ChatContainer, 
  ChatHeader, 
  ChatBody, 
  ChatBubble, 
  ChatInputContainer, 
  ChatBubbleMenuContainer,
  MenuIconContainer
} from './styled';
import { 
  Drawer, 
  IconButton, 
  Typography, 
  TextField, 
  Button, 
  ListItemText,
  Avatar,
  InputAdornment,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import TelegramIcon from '@mui/icons-material/Telegram';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCollabChat, sendNewChat } from '../../actions/collaboratorActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import gtmTrackButtonClick from '../../utils/gtmTrackButtonClick';

function CollaboratorChat({ 
  isMobile, 
  setCollabChatOpen, 
  userIsOwner,
  documentId,
  docCollaboratorChats,
}) {

  const userInfo = useCurrentUserInfo();
  const dispatch = useDispatch();
  const collabChatUpdating = useSelector((state) => state.gptChat.collabChatUpdating);

  const [newChatMessage, setNewChatMessage] = useState("");
  const [documentChats, setDocumentChats] = useState(docCollaboratorChats);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedCollabChat, setSelectedCollabChat] = useState();

  const handleMenuOpen = (event, msg) => {
    setSelectedCollabChat(msg);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setSelectedCollabChat(null);
    setAnchorEl(null);
  };

  const sendNewChatMessage = () => {
    dispatch( 
      sendNewChat({ 
        userId: userInfo?.user_id, 
        documentId: documentId,
        chatMessageContent: newChatMessage
      })
    );
  };

  const handleSendMessage = () => {
    sendNewChatMessage();
    setNewChatMessage("");
  };

  const editChat = () => {

  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = () => {
    dispatch(
      deleteCollabChat(selectedCollabChat?._id, documentId, dispatch)
    );

    setDeleteDialogOpen(false);
    setSelectedCollabChat(null);
  };

  useMemo(() => {
    setDocumentChats(docCollaboratorChats);
  }, [docCollaboratorChats]);

  const chatContent = (
    <ChatContainer>
      <ChatHeader>
        <Typography variant="h6">Document Chat</Typography>

        {/* Do permission edits later */}
        {/* {userIsOwner() && (
          <Button 
            variant="outlined" 
            startIcon={<SettingsIcon />}
            onClick={() => console.log('Adjust permissions')}
          >
            Permissions
          </Button>
        )} */}
        <IconButton onClick={() => setCollabChatOpen(false)}>
          <CloseIcon />
        </IconButton>
      </ChatHeader>
      <ChatBody>
        {documentChats?.map((msg, index) => (
          <ChatBubbleMenuContainer 
            isCurrentUser={msg?.userId === userInfo?.user_id} 
            userIsOwner={userIsOwner()}
          >
            <ChatBubble key={index} isCurrentUser={msg?.userId === userInfo?.user_id}>
              <Avatar src={msg?.userAvatar} sx={{ flex: '1', maxWidth: '45px', minWidth: '45px', height: '45px' }} />
              <ListItemText 
                primary={msg?.chatMessageContent} 
                secondary={msg?.userName}
                sx={{ flex: '5', margin: '0 1rem' }} 
              />
            </ChatBubble>
            <MenuIconContainer id="hoverGrid" isCurrentUser={msg?.userId === userInfo?.user_id}>
              <IconButton onClick={(e) => handleMenuOpen(e, msg)}>
                <MoreVertIcon />
              </IconButton>
            </MenuIconContainer>
          </ChatBubbleMenuContainer>
        ))}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {/* <MenuItem onClick={handleMenuClose}>
            <ModeEditOutlineOutlinedIcon sx={{marginRight: '0.5rem'}} />
            <Typography>
              Edit Message
            </Typography>
          </MenuItem> */}
          <MenuItem onClick={handleDeleteClick}>
            <DeleteForeverOutlinedIcon sx={{marginRight: '0.5rem', color: 'red'}} />
            <Typography sx={{ color: 'red' }}>
              Delete Message
            </Typography>
          </MenuItem>
        </Menu>
        <Dialog
          open={deleteDialogOpen}
          onClose={handleDialogClose}
        >
          <DialogTitle>{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this message?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              variant='contained'
              onClick={handleDialogClose} 
              color='primary'
              sx={{ color: 'white' }}
            >
              Cancel
            </Button>
            <Button 
              variant='outlined' 
              onClick={handleDeleteConfirm} 
              sx={{
                color: 'gray',
                borderColor: 'gray'
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </ChatBody>
      <ChatInputContainer>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a message..."
          value={newChatMessage}
          onChange={(e) => setNewChatMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSendMessage();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {
                  collabChatUpdating ? (
                    <CircularProgress color='secondary' />
                  ) : (
                    <IconButton
                      color="secondary" 
                      sx={{
                        background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`
                      }}
                      onClick={(e) => {
                        gtmTrackButtonClick(e, 'click_sendChat');
                        sendNewChatMessage();
                      }}
                    >
                      <TelegramIcon sx={{margin: '0'}}/>
                    </IconButton>
                  )
                }
              </InputAdornment>
            ),
          }}
        />
      </ChatInputContainer>
    </ChatContainer>
  );

  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={() => setCollabChatOpen(false)}
      variant={"persistent"}
      sx={{
        '& .MuiDrawer-paper': {
          width: isMobile ? '100%' : '30%',
          padding: 0,
          boxSizing: 'border-box',
          ...(isMobile && {
            height: '100dvh'
          }),
          zIndex: '1300'
        }
      }}
    >
      {chatContent}
    </Drawer>
  );
};

export default CollaboratorChat;