export const GET_USER_FOLDERS_REQUEST = 'GET_USER_FOLDERS_REQUEST';
export const GET_USER_FOLDERS_SUCCESS = 'GET_USER_FOLDERS_SUCCESS';
export const GET_USER_FOLDERS_FAIL = 'GET_USER_FOLDERS_FAIL';

export const CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_FAIL = 'CREATE_FOLDER_FAIL';

export const DOC_FOLDER_UPDATE_REQUEST = 'DOC_FOLDER_UPDATE_REQUEST';
export const DOC_FOLDER_UPDATE_SUCCESS = 'DOC_FOLDER_UPDATE_SUCCESS';
export const DOC_FOLDER_UPDATE_FAIL = 'DOC_FOLDER_UPDATE_FAIL';