import React, { useEffect, useMemo, useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  List, 
  ListItem, 
  ListItemAvatar,
  Avatar, 
  ListItemText, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Button, 
  Box, 
  IconButton,
  Grid,
  Typography,
  Tooltip,
  CircularProgress
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useDispatch, useSelector } from 'react-redux';
import { 
  removeCollaborators, 
  removeInvite, 
  sendCollabInvite, 
  updateCollaboratorsPermissions 
} from '../../actions/collaboratorActions';
import AddCollaboratorDialog from './AddCollabDialog';
import { STORE_TEMP_INVITE_DATA } from '../../consts/collaboratorsConstants';
import RefreshIcon from '@mui/icons-material/Refresh';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';
import gtmTrackButtonClick from '../../utils/gtmTrackButtonClick';
import { mergeCollaborators } from './helpers';

const CollaboratorsDialog = ({ 
  open, 
  onClose, 
  collaborators, 
  folderCollaborators,
  setCollaboratorsOpen,
  onSave,
  selectedFileData,
  isMobile
}) => {

  const dispatch = useDispatch();
  const userInfo = useCurrentUserInfo();

  const inviteProcessData = useSelector((state) => state?.collaborators?.invites);
  
  const [localCollaborators, setLocalCollaborators] = useState([]);

  const [addCollabOpen, setAddCollabOpen] = useState(false);
  const [invitees, setInvitees] = useState([]);
  const [updatedCollabPermissions, setUpdatedCollabPermissions] = useState([]);
  const [deletedCollabs, setDeletedCollabs] = useState([]);

  const mergeAndFilterUniqueCollaborators = mergeCollaborators(collaborators, folderCollaborators);

  const userIsOwner = () => {
    if(selectedFileData?.ownerId !== userInfo?.user_id){
      return false
    };

    return true;
  };

  const noInviteesOrCollaborators = () => {
    if(!invitees?.length && !localCollaborators?.length){
      return true;
    };

    return false;
  };

  const handleRemoveCollaborator = (collaborator) => {
    setLocalCollaborators((prevCollaborators) =>
      prevCollaborators.filter((item) => item.user_id !== collaborator.user_id)
    );

    setDeletedCollabs([...deletedCollabs, collaborator]);
  };

  const handlePermissionChange = (index, newPermission, collaborator) => {
    const updatedCollaborators = localCollaborators.map((user) => {
      if (user.user_id === collaborator?.user_id) {

        return { ...collaborator, permission: newPermission };
      };

      return user;
    });

    setLocalCollaborators(updatedCollaborators);
  };

  const handleSendCollabInvite = ({ email, permission }) => {

    dispatch({ type: STORE_TEMP_INVITE_DATA, payload: { email, permission }});

    dispatch(
      sendCollabInvite(
        {
          messageId: selectedFileData?._id, 
          recipientEmail: email,
          permission: permission
        },
        dispatch
      )
    );
  };

  const handleSave = () => {
    if(updatedCollabPermissions.length > 0){
      dispatch( 
        updateCollaboratorsPermissions(
          { 
            updatedCollabs: updatedCollabPermissions,
            messageId: selectedFileData?._id
          },
          dispatch
        )
      );
    };

    if(deletedCollabs.length > 0){
      dispatch(
        removeCollaborators(
          {
            deletedCollabs: deletedCollabs,
            messageId: selectedFileData?._id
          },
          dispatch
        )
      );
    };

    setDeletedCollabs([]);
    setUpdatedCollabPermissions([]);
    onSave(localCollaborators);
    setCollaboratorsOpen(false);
  };

  const handleCancelInvite = ({ messageId, inviteId }) => {
    dispatch(
      removeInvite({ messageId, inviteId }, dispatch)
    );
  };

  const handleCancelEdits = () => {
    setLocalCollaborators(mergeAndFilterUniqueCollaborators);
    setUpdatedCollabPermissions([]);
    setDeletedCollabs([]);
    setCollaboratorsOpen(false);
  };
  
  useMemo(() => {
    setLocalCollaborators(mergeAndFilterUniqueCollaborators);
  }, [collaborators, folderCollaborators]);

  useMemo(() => {

    if(inviteProcessData?.tempInviteInfo && inviteProcessData?.sendingInvite){
      const filterFailedInvitees = invitees.filter((invitee) => invitee?.invitFailed);

      setInvitees([...filterFailedInvitees, inviteProcessData?.tempInviteInfo]);
    };
    
    if(!inviteProcessData?.inviteSendError){
      setInvitees(selectedFileData?.invites ?? []);
    };

    if(inviteProcessData?.inviteSendError){
      setInvitees([...selectedFileData?.invites, inviteProcessData?.tempInviteInfo]);
    };

    // eslint-disable-next-line
  }, [inviteProcessData, selectedFileData]);

  useEffect(() => {
    const findDifferentPermissions = () => {


      return localCollaborators.filter(localColl => {
        const coll = mergeAndFilterUniqueCollaborators.find(coll => coll.user_id === localColl.user_id);
        return coll && localColl.permission !== coll.permission;
      });
    };

    const diffPermissions = findDifferentPermissions();

    setUpdatedCollabPermissions(diffPermissions);
  }, [collaborators, localCollaborators]);


  // Updating Invites Rows for Mobile
  const RenderInviteeIcon = ({invitee}) => {
    if(invitee?.inviteFailed && !inviteProcessData?.sendingInvite){
      return (
        <Tooltip title='Resend'>
          {
            isMobile ? (
              <Button 
                variant='outlined' 
                sx={{ marginTop: '1rem'}} 
                onClick={() => handleSendCollabInvite(
                  {
                    email: inviteProcessData?.tempInviteInfo?.email,
                    permission: inviteProcessData?.tempInviteInfo?.permission
                  }
                )}
              >
                <Typography>
                  Retry Invite
                </Typography>
              </Button>
            ) : (
              <IconButton 
                onClick={() => handleSendCollabInvite(
                  {
                    email: inviteProcessData?.tempInviteInfo?.email,
                    permission: inviteProcessData?.tempInviteInfo?.permission
                  }
                )}
              >
                <RefreshIcon />
              </IconButton>
            )
          }
        </Tooltip>
      )
    };

    if(
      inviteProcessData?.sendingInvite && 
      invitee?.inviteId === inviteProcessData?.tempInviteInfo?.inviteId
    ){

      if(isMobile){
        return (
          <Button 
            variant='outlined' 
            sx={{
              color: 'gray', 
              borderColor: 'gray',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography>
              Sending
            </Typography>
            <CircularProgress sx={{color: '#84A8FF'}} />
          </Button>
        )
      };

      return (
        <CircularProgress sx={{color: '#84A8FF'}} />
      );
    };

    if(isMobile){
      return (
        <Tooltip title='Cancel Invite'>
          <Button 
            onClick={() => handleCancelInvite(
              {
                messageId: selectedFileData?._id,
                inviteId: invitee?.inviteId
              }
            )}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography>
              Cancel Invite
            </Typography>
            <RemoveCircleOutlineIcon sx={{color: 'red'}} />
          </Button>
        </Tooltip>
      )
    };

    return (
      <Tooltip title='Cancel Invite'>
        <IconButton 
          onClick={() => handleCancelInvite(
            {
              messageId: selectedFileData?._id,
              inviteId: invitee?.inviteId
            }
          )}
        >
          <RemoveCircleOutlineIcon sx={{color: 'red'}} />
        </IconButton>
      </Tooltip>
    )
  };

  const CollaboratorList = () => {
    if(localCollaborators?.length > 0){
      return (
        <List 
          sx={{ 
            maxHeight: 300, 
            overflow: 'auto', 
            width: '100%',
          }}
        >
          {
            localCollaborators?.map((collaborator, index) => (
              <ListItem 
                key={index} 
                divider 
                sx={{
                  display: 'flex', 
                  flexDirection: isMobile && 'column'
                }}
              >
                <ListItemAvatar sx={{flex: '1', display: isMobile && 'flex', justifyContent: isMobile && 'center'}}>
                  <Avatar src={collaborator?.picture} alt={collaborator?.username} />
                </ListItemAvatar>
                <ListItemText 
                  primary={collaborator?.username} 
                  secondary={collaborator?.email ?? ''} 
                  sx={{flex: '4', textAlign: isMobile && 'center'}} 
                />
                <FormControl fullWidth variant="outlined" sx={{ minWidth: 120, flex: '4', marginTop: isMobile && '0.5rem' }}>
                  <InputLabel>Permission</InputLabel>
                  <Select
                    value={collaborator?.permission}
                    defaultValue={'canView'}
                    onChange={(e) => handlePermissionChange(index, e.target.value, collaborator)}
                    label="Permission"
                    disabled={!userIsOwner() || collaborator?.status === 'processing'}
                  >
                    <MenuItem value="canView">Viewer</MenuItem>
                    <MenuItem value="canEdit">Editor</MenuItem>
                  </Select>
                </FormControl>
                <Grid 
                  sx={{
                    flex: '1', 
                    display: userIsOwner() ? 'flex' : 'none', 
                    width: '100%', 
                    justifyContent: 'center'
                  }}
                >
                  <Tooltip title='Remove'>
                    {
                      isMobile ? (
                        <Button 
                          variant='outlined' 
                          sx={{ color: 'red', borderColor: 'red', marginTop: '1rem'}} 
                          onClick={() => handleRemoveCollaborator(collaborator)}
                        >
                          <Typography>
                            Remove
                          </Typography>
                        </Button>
                      ) : (
                        <IconButton onClick={() => handleRemoveCollaborator(collaborator)}>
                          <RemoveCircleOutlineIcon sx={{color: 'red'}} />
                        </IconButton>
                      )
                    }
                  </Tooltip>
                </Grid>
              </ListItem>
            ))
          }
        </List>
      );
    };

    if(noInviteesOrCollaborators()){
      return (
        <Grid 
          sx={{
            width: '100%', 
            height: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center'
          }}
        >
          <Typography>
            No Current Collaborators
          </Typography>
        </Grid>
      );
    };

    return null;
  };

  const InviteesList = () => {
    if(invitees?.length > 0){
      return (
        <List 
          sx={{ 
            maxHeight: 300,
            overflow: 'auto', 
            width: '100%'
          }}
        >
          {
            invitees?.map((invitee, index) => (
              <ListItem key={index} divider sx={{display: 'flex', flexDirection: isMobile && 'column'}}>
                <ListItemAvatar sx={{flex: '1', display: isMobile && 'flex', justifyContent: isMobile && 'center'}}>
                  <Avatar alt={invitee?.email} />
                </ListItemAvatar>
                <ListItemText 
                  primary={invitee?.email} 
                  secondary={invitee?.inviteFailed ? 'Invite Failed' : 'Invite Pending'}
                  sx={{flex: '4', textAlign: isMobile && 'center'}} 
                />
                <FormControl fullWidth variant="outlined" sx={{ minWidth: 120, flex: '4', marginTop: isMobile && '0.5rem' }}>
                  <InputLabel>Permission</InputLabel>
                  <Select
                    value={invitee.permission}
                    label="Permission"
                    disabled
                  >
                    <MenuItem value="canView">Can View</MenuItem>
                    <MenuItem value="canEdit">Can Edit</MenuItem>
                  </Select>
                </FormControl>
                <Grid 
                  sx={{
                    flex: '1', 
                    display: userIsOwner() ? 'flex' : 'none', 
                    width: '100%', 
                    justifyContent: 'center'
                  }}
                >
                  <RenderInviteeIcon invitee={invitee} />
                </Grid>
              </ListItem>
            ))
          }
        </List>
      );
    };

    return null;
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography sx={{fontSize: isMobile ? '1.25rem' : '1.5rem'}}>
            Collaborators
          </Typography>
          <Button 
            variant='outlined'
            color='secondary'
            sx={{
              display: userIsOwner() ? 'flex' : 'none', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              width: '8rem',
              background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)` 
            }}
            onClick={(e) => {
              gtmTrackButtonClick(e, 'click_addCollaborator');
              setAddCollabOpen(true);
            }}
          >
            <PersonAddAlt1Icon color='secondary' />
            <Typography color={'secondary'}>
              Add User
            </Typography>
          </Button>
        </DialogTitle>
        <DialogContent 
          dividers 
          sx={{
            minHeight: '20rem', 
            display: 'flex', 
            alignItems: noInviteesOrCollaborators() && 'center', 
            justifyContent: noInviteesOrCollaborators() && 'center',
            width: '100%',
            flexDirection: 'column',
            overflowY: 'scroll'
          }}
        >
          <CollaboratorList />
          <InviteesList />
        </DialogContent>
        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />
          <Button 
            variant='outlined' 
            onClick={handleCancelEdits} 
            sx={{
              color: 'gray',
              borderColor: 'lightgray'
            }}
          >
            Cancel
          </Button>
          <Button 
            variant='contained' 
            onClick={handleSave} 
            sx={{
              background: 'linear-gradient(to left, #507CE6 0%, #84A8FF 100%)',
              color: 'white'
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <AddCollaboratorDialog
        open={addCollabOpen}
        onClose={() => setAddCollabOpen(false)}
        onConfirm={handleSendCollabInvite}
        isMobile={isMobile}
      />
    </>
  );
};

export default CollaboratorsDialog;