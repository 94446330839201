import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import {
  MainContentArea,
  MainContentAreaChildGrid
} from './styled';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DashDocListItem from '../../components/DashDocListItem';
import DashFolderListItem from '../../components/DashFolderListItem';
import HistoryListItem from '../../containers/ChatHistory/HistoryListItem';
import { 
  ownedMessageFolders as ownedFolders, 
  sharedMessageFolders,
  ownedMessages
} from '../FoldersDemo/constants';


function DashboardContainer() {

  const navigate = useNavigate();

  const externalMessages = useSelector((state) => state.gptChat.externalDbMessages);

  const conversationHistory = localStorage.getItem('conversationHistory');
  const parsedConversationHistory = JSON.parse(conversationHistory);
  const sorted = parsedConversationHistory?.sort((a, b) => new Date(b?.dateTime) - new Date(a?.dateTime));
  const lastTenAIChats = sorted?.slice(0, 10);

  const [sharedView, setSharedView] = useState('Folders');

  const sortOwnedMessagesByDate = () => {
    return ownedMessages?.sort((message) => message.dateSaved)?.slice(0, 10);
  };

  const sortSharedMessagesByDate = () => {
    return externalMessages?.sort((message) => message?.dateSaved)?.slice(0, 10);
  };  

  const sortSharedFoldersByDate = () => {
    return sharedMessageFolders?.sort((folder) => folder?.dateCreated)?.slice(0, 10);
  };  

  const sortOwnedFoldersByDate = () => {
    return ownedFolders?.sort((folder) => folder?.dateCreated)?.slice(0, 10);
  };  

  const DataNoDataConditional = ({dataType}) => {
    switch(dataType){
      case 'Folders':
        if(ownedFolders.length > 0){
          return (
            sortOwnedFoldersByDate()?.map((folder) => (
              <Paper
                elevation={0} 
                sx={{ 
                  marginBottom: '0.5rem', 
                  marginRight: '1rem', 
                  border: '1px solid lightgray'
                }}
              >
                <DashFolderListItem 
                  folderName={folder?.name}
                  folderId={folder?._id}
                  shared={false}
                />
              </Paper>
            ))
          );
        };

        return (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              flexDirection: 'column'
            }}  
          >
            <Typography>No Saved Folders</Typography>
            <Button color='primary' onClick={() => navigate('/folders')}>
              Add New Folders
            </Button>
          </Grid>
        );
      case 'SavedDocs':

        if(ownedMessages?.length > 0){
          return (
            sortOwnedMessagesByDate()?.map((message) => (
              <DashDocListItem messageData={message} />
            ))
          );
        };

        return (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              flexDirection: 'column'
            }}  
          >
            <Typography>No Saved Messages</Typography>
            <Button color='primary' onClick={() => navigate('/ai-chat')}>
              Start New A.I. Chat
            </Button>
          </Grid>
        )
      case 'SharedFolders':

        if(sharedMessageFolders?.length > 0){
          return (
            sharedMessageFolders.map((folder) => (
              <Paper 
                elevation={0} 
                sx={{ 
                  marginBottom: '0.5rem', 
                  marginRight: '1rem', 
                  border: '1px solid lightgray'
                }}
              >
                <DashFolderListItem 
                  folderName={folder?.name}
                  folderId={folder?._id}
                  shared={true}
                />
              </Paper>
            ))
          )
        };

        return (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%'
            }}  
          >
            <Typography>
              No Folders Shared With You Yet!
            </Typography>
          </Grid>
        )
      case 'SharedDocs':

        if(sharedMessageFolders?.length > 0){
          return (
            sortSharedMessagesByDate()?.map((message) => (
              <DashDocListItem 
                messageData={message}
              />
            ))
          )
        };

        return (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%'
            }}  
          >
            <Typography>
              No Docs Shared With You Yet!
            </Typography>
          </Grid>
        )
      case 'ChatHistory':
        
        if(lastTenAIChats?.length > 0){
          return (
            lastTenAIChats?.map((entry) => (
              <HistoryListItem
                dateTime={entry?.dateTime}
                key={entry?.dateTime}
                messages={entry?.messageThread}
              />
            ))
          )
        };

        return (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              flexDirection: 'column'
            }}  
          >
            <Typography>No Chat History</Typography>
            <Button color='primary' onClick={() => navigate('/ai-chat')}>
              Start New A.I. Chat
            </Button>
          </Grid>
        );
      default: return null;
    }
  };


  const RenderSharedView = () => {
    if(sharedView === 'Folders'){
      return (
        <DataNoDataConditional dataType={"SharedFolders"} />
      )
    };

    if(sharedView === 'Messages'){
      return (
        <DataNoDataConditional dataType={"SharedDocs"} />
      )
    };

    return null;
  };

  return (
    <MainContentArea elevation={0}>
      <MainContentAreaChildGrid container spacing={2}>
        <Grid 
          item 
          xs={12} 
          sm={6} 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '50%'
          }}
        >
          <Paper 
            id={"saved-messsages-dash"}
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '4px',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              p: 2
            }}
          >
            <Box 
              display="flex"
              justifyContent="space-between" 
              alignItems="center" 
              sx={{ marginBottom: '1rem' }}
            >
              <Typography variant="h6">Recently Saved</Typography>
              <Button 
                variant="contained" 
                sx={{ color: 'white'}}
                onClick={() => navigate('/ai-chat')}
              >
                New A.I. Chat
              </Button>
            </Box>
            <Grid sx={{ overflow: 'auto', height: '100%', width: '100%' }}>
              <DataNoDataConditional dataType={"SavedDocs"} />
            </Grid>
          </Paper>
        </Grid>
        <Grid 
          item 
          xs={12} 
          sm={6} 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '50%'
          }}
        >
          <Paper
            id={'shared-folders-documents-dash'} 
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '4px',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              p: 2
            }}
          >
            <Box 
              display="flex"
              justifyContent="space-between" 
              alignItems="center" 
              sx={{ marginBottom: '1rem' }}
            >
              <Grid 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  width: '100%',
                  flexWrap: 'wrap'
                }}
              >
                <Typography 
                  variant="h6"
                  sx={{ marginRight: '0.5rem'}}
                >
                  Shared With You -
                </Typography>
                <Typography variant="h6" color={'primary'} >{sharedView}</Typography>
              </Grid>
              <ButtonGroup 
                variant="contained" 
                aria-label="Basic button group"
              >
                <Button sx={{ color: 'white' }} onClick={() => setSharedView('Messages')}>Messages</Button>
                <Button sx={{ color: 'white' }} onClick={() => setSharedView('Folders')}>Folders</Button>
              </ButtonGroup>
            </Box>
            <Grid sx={{ overflow: 'auto', height: '100%', width: '100%' }}>
              <RenderSharedView />
            </Grid>
          </Paper>
        </Grid>
        <Grid 
          item 
          xs={12} 
          sm={6} 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '50%'
          }}
        >
          <Paper 
            id={'saved-folders-dash'}
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '4px',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              p: 2
            }}
          >
            <Box 
              display="flex"
              justifyContent="space-between" 
              alignItems="center" 
              sx={{ marginBottom: '1rem' }}
            >
              <Typography variant="h6">Recent Folders</Typography>
              <Button 
                variant="contained" 
                sx={{ color: 'white' }}
                onClick={() => navigate('/folders')}
              >
                View Folders
              </Button>
            </Box>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                overflow: 'auto',
                height: '100%', 
                width: '100%'
              }}
            >
              <DataNoDataConditional dataType={"Folders"} />
            </Grid>
          </Paper>
        </Grid>
        <Grid 
          item 
          xs={12} 
          sm={6} 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '50%'
          }}
        >
          <Paper 
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '4px',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              overflow: 'auto',
              p: 2,
            }}
          >
            <Box 
              display="flex"
              justifyContent="space-between" 
              alignItems="center"
              sx={{ marginBottom: '1rem' }}
            >
              <Typography variant="h6">AI Chat History</Typography>
              <Button 
                variant="contained" 
                sx={{ color: 'white' }}
                onClick={() => navigate('/chat-history')}
              >
                View All History
              </Button>
            </Box>
            <Grid sx={{ overflow: 'auto', height: '100%', width: '100%' }}>
              <DataNoDataConditional dataType={"ChatHistory"} />
            </Grid>
          </Paper>
        </Grid>
      </MainContentAreaChildGrid>
    </MainContentArea>
  )
}

export default DashboardContainer;