import axios from "axios";
import { 
  DELETE_GPT_FOLDER_FAIL, 
  DELETE_GPT_FOLDER_REQUEST, 
  DELETE_GPT_FOLDER_SUCCESS, 
  UPDATE_FOLDER_NAME_FAIL,
  UPDATE_FOLDER_NAME_REQUEST,
  UPDATE_FOLDER_NAME_SUCCESS
} from "../consts/gptChatConstants";
import { 
  CREATE_FOLDER_FAIL, 
  CREATE_FOLDER_REQUEST, 
  CREATE_FOLDER_SUCCESS, 
  DOC_FOLDER_UPDATE_FAIL, 
  DOC_FOLDER_UPDATE_REQUEST, 
  DOC_FOLDER_UPDATE_SUCCESS, 
  GET_USER_FOLDERS_FAIL, 
  GET_USER_FOLDERS_REQUEST, 
  GET_USER_FOLDERS_SUCCESS 
} from "../consts/docmentConstants";

export const config = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

export const createUserFolder = ({userId, newFolderName}) => async(dispatch) => {
  const path = `${process.env.REACT_APP_API_PATH}api/folders/createFolder`;
  
  dispatch({ type: CREATE_FOLDER_REQUEST });

  try{
    const response = await axios.post(
      path, 
      {
        userId: userId,
        folderName: newFolderName
      },
      config
    );

    if(response?.status === 200){
      dispatch({ type: CREATE_FOLDER_SUCCESS, payload: response?.data?.folderData });
    };

  } catch (error) {
    console.error('Error: ', error.message);
    dispatch({ type: CREATE_FOLDER_FAIL, payload: error.message });
  };
};

export const deleteGPTFolder = (userId, folderId) => async(dispatch) => {

  const path = `${process.env.REACT_APP_API_PATH}api/folders/deleteFolder`;

  dispatch({ type: DELETE_GPT_FOLDER_REQUEST });

  try{
    const response = await axios.post(
      path, 
      {
        userId: userId, 
        folderId: folderId
      }, 
      config
    );
    
    if(response?.status === 200){
      dispatch({ 
        type: DELETE_GPT_FOLDER_SUCCESS, 
        payload: { folderId: folderId } 
      });
    };

  } catch (error) {
    dispatch({ type: DELETE_GPT_FOLDER_FAIL, payload: {error: error.message}});
  };
};

export const updateFolderName = (folderId, updatedFolderName) => async(dispatch) => {

  const path = `${process.env.REACT_APP_API_PATH}api/folders/updateFolderName`;

  dispatch({ type: UPDATE_FOLDER_NAME_REQUEST });

  try{
    const response = await axios.post(
      path, 
      {
        folderName: updatedFolderName,
        folderId: folderId
      }, 
      config
    );
    
    if(response?.status === 200){
      dispatch({ 
        type: UPDATE_FOLDER_NAME_SUCCESS, 
        payload: { folderName: updatedFolderName, folderId: folderId } 
      });
    };

  } catch (error) {
    dispatch({ type: UPDATE_FOLDER_NAME_FAIL, payload: {error: error.message}});
  };
};

export const retrieveUserFolders = (userId) => async(dispatch) => {
  const path = `${process.env.REACT_APP_API_PATH}api/folders/${userId}`;

  dispatch({ type: GET_USER_FOLDERS_REQUEST });

  try{
    const response = await axios.get(path, config);
    
    if(response?.status === 200){

      dispatch({ 
        type: GET_USER_FOLDERS_SUCCESS, 
        payload: { 
          externalFolders: response?.data?.externalFolders,
          ownedFolders: response?.data?.ownedFolders
        } 
      });
    };

  } catch (error) {
    console.log('error', error);
    dispatch({ type: GET_USER_FOLDERS_FAIL, payload: { error: error.message }});
  };
};

export const documentFolderUpdate = ({ docsToMove, updatedFolderId }) => async(dispatch) => {
  
  const docNeededIds = docsToMove?.map((doc) => ({
    messageId: doc?._id,
    folderId: updatedFolderId
  }));

  const path = `${process.env.REACT_APP_API_PATH}api/messages/bulkFolderUpdate`;

  dispatch({ type: DOC_FOLDER_UPDATE_REQUEST });

  try{
    const response = await axios.post(path, { docNeededIds }, config);
    
    if(response?.status === 200){

      dispatch({ 
        type: DOC_FOLDER_UPDATE_SUCCESS, 
        payload: response?.data 
      });
    };

  } catch (error) {
    dispatch({ type: DOC_FOLDER_UPDATE_FAIL, payload: { error: error.message }});
  };
};