import { retrieveCollaboratorUsersInfo } from "../actions/collaboratorActions";

const collaboratorsUserInfo = ({
  sharedObjectData, 
  allUserCollaboratorIds,
  dispatch
}) => {

  const collaboratorsIds = sharedObjectData?.sharedWith?.map(
    (user) => user.user_id
  );

  const filterUnfetchedCollaborators = (ids, allUsers) => {
    return ids?.filter(id => !allUsers?.some(user => user?.user_id === id));
  };

  const missingCollaboratorIds = filterUnfetchedCollaborators(
    collaboratorsIds, 
    allUserCollaboratorIds
  );

  if(missingCollaboratorIds.length){
    dispatch(retrieveCollaboratorUsersInfo(missingCollaboratorIds, dispatch));
  };
};

export default collaboratorsUserInfo;