import { Grid, styled } from "@mui/material";


const ParentGrid = styled(Grid)(({theme}) => ({
  display: 'flex',
  width: '100%',
  height: '4rem',
  padding: '0.5rem',
  marginBottom: '0.5rem'
}));

const InnerGridBackgroundContainer = styled(Grid)(({theme}) => ({
  width: '100%', 
  height: '4rem',
  backgroundColor: `#f9fbff`, 
  borderRadius: '1rem',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'nowrap',
}));

const DocumentContentGrid = styled(Grid)(({theme}) => ({
  flex: '4',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  borderRadius: '1rem',
  paddingLeft: '1rem'
}));

const CollabButtonGrid = styled(Grid)(({theme}) => ({
  flex: '1',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderRadius: '1rem',
  marginRight: '1rem'
}));

const FolderEditGrid = styled(Grid)(({theme}) => ({
  flex: '1',
  height: '100%'
}));

export {
  ParentGrid,
  InnerGridBackgroundContainer,
  DocumentContentGrid,
  CollabButtonGrid,
  FolderEditGrid
}