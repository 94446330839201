const ownedMessageFolders = [
  {
    _id: '1',
    invites: [
      {
        email: 'test@email.com',
        inviteId: 0,
        permission: 'canEdit'
      }
    ],
    name: 'Custom Folder',
    ownerId: 0,
    sharedWith: [
      {
        user_id: '6681b587138acc8bcf3b7904',
        id: '670acc07a282b322e67d6320'
      }
    ]
  }
];

const sharedMessageFolders = [
  {
    _id: '2',
    invites: [
      {
        email: 'test@email.com',
        inviteId: 0,
        permission: 'canEdit'
      }
    ],
    name: 'Shared Folder',
    ownerId: 1,
    sharedWith: [
      {
        user_id: '6681b587138acc8bcf3b7904',
        id: '670acc07a282b322e67d6320'
      }
    ]
  }
];

const filteredDocs = [
  {
    collabChats: [
      {
        chatMessageContent: "Thuper gay",
        timestamp: "2024-09-09T21:50:03.614Z",
        userAvatar: "https://lh3.googleusercontent.com/a/ACg8ocLD7f3_4sH48kHfa5J_lR4JI-Qk3xpt0bx9dMb5hbX_TEYhjVQ=s96-c",
        userId: "6681b587138acc8bcf3b7904",
        userName: "AwesomeSauces"
      }
    ],
    content:  `
    <h2>Course Outline: Introduction to Roman History</h2>
    <h3>Week 1: The Foundation of Rome</h3>
    <ul>
      <li>Legend of Romulus and Remus</li>
      <li>The monarchy period</li>
    </ul>

    <h3>Week 2: The Roman Republic</h3>
    <ul>
      <li>Structure of the Republic</li>
      <li>Expansion of Roman territories</li>
      <li>Conflict of the Orders</li>
    </ul>

    <h3>Week 3: The Rise of Julius Caesar</h3>
    <ul>
      <li>Julius Caesar's military campaigns</li>
      <li>The First Triumvirate</li>
      <li>Assassination of Julius Caesar</li>
    </ul>

    <h3>Week 4: The Roman Empire</h3>
    <ul>
      <li>Formation of the Empire</li>
      <li>Augustus and the Pax Romana</li>
      <li>Emperors and Dynasties</li>
    </ul>

    <h3>Week 5: Roman Society and Culture</h3>
    <ul>
      <li>Social classes in Rome</li>
      <li>Roman art, literature, and architecture</li>
    </ul>

    <h3>Week 6: Decline and Fall of the Roman Empire</h3>
    <ul>
      <li>Causes of the decline</li>
      <li>Barbarian invasions</li>
      <li>Legacy of the Roman Empire</li>
    </ul>
  `,
    docName: 'Demo Document',
    folderId: 1,
    invites: [],
    ownerId: 0,
    savedMessageHistory: [
      {
        content: "Write me a simple well structured course outline for an Intro to Roman History.",
        role: "user"
      },
      {
        content: `<h2>Course Outline: Introduction to Roman History</h2>
          <h3>Week 1: The Foundation of Rome</h3>
          <ul>
            <li>Legend of Romulus and Remus</li>
            <li>The monarchy period</li>
          </ul>
          <h3>Week 2: The Roman Republic</h3>
          <ul>
            <li>Structure of the Republic</li>
            <li>Expansion of Roman territories</li>
            <li>Conflict of the Orders</li>
          </ul>
          <h3>Week 3: The Rise of Julius Caesar</h3>
          <ul>
            <li>Julius Caesar's military campaigns</li>
            <li>The First Triumvirate</li>
            <li>Assassination of Julius Caesar</li>
          </ul>
          <h3>Week 4: The Roman Empire</h3>
          <ul>
            <li>Formation of the Empire</li>
            <li>Augustus and the Pax Romana</li>
            <li>Emperors and Dynasties</li>
          </ul>
          <h3>Week 5: Roman Society and Culture</h3>
          <ul>
            <li>Social classes in Rome</li>
            <li>Roman art, literature, and architecture</li>
          </ul>
          <h3>Week 6: Decline and Fall of the Roman Empire</h3>
          <ul>
            <li>Causes of the decline</li>
            <li>Barbarian invasions</li>
            <li>Legacy of the Roman Empire</li>
          </ul>
        `,
        role: "assistant"
      }
    ] 
  }
];

const ownedMessages = [
  {
    collabChats: [],
    content: `<h2>Course Outline: Introduction to Roman History</h2>
      <h3>Week 1: The Foundation of Rome</h3>
      <ul>
        <li>Legend of Romulus and Remus</li>
        <li>The monarchy period</li>
      </ul>
      <h3>Week 2: The Roman Republic</h3>
      <ul>
        <li>Structure of the Republic</li>
        <li>Expansion of Roman territories</li>
        <li>Conflict of the Orders</li>
      </ul>
      <h3>Week 3: The Rise of Julius Caesar</h3>
      <ul>
        <li>Julius Caesar's military campaigns</li>
        <li>The First Triumvirate</li>
        <li>Assassination of Julius Caesar</li>
      </ul>
      <h3>Week 4: The Roman Empire</h3>
      <ul>
        <li>Formation of the Empire</li>
        <li>Augustus and the Pax Romana</li>
        <li>Emperors and Dynasties</li>
      </ul>
      <h3>Week 5: Roman Society and Culture</h3>
      <ul>
        <li>Social classes in Rome</li>
        <li>Roman art, literature, and architecture</li>
      </ul>
      <h3>Week 6: Decline and Fall of the Roman Empire</h3>
      <ul>
        <li>Causes of the decline</li>
        <li>Barbarian invasions</li>
        <li>Legacy of the Roman Empire</li>
      </ul>
    `,
    docName: "Demo Document",
    folderId: "671171247df2581e4d3082bb",
    invites: [],
    ownerId: "6681b587138acc8bcf3b7904",
    savedMessageHistory: [
      {
        content: "Write me a simple well structured course outline for an Intro to Roman History.",
        role: "user"
      },
      {
        content: `<h2>Course Outline: Introduction to Roman History</h2>
          <h3>Week 1: The Foundation of Rome</h3>
          <ul>
            <li>Legend of Romulus and Remus</li>
            <li>The monarchy period</li>
          </ul>
          <h3>Week 2: The Roman Republic</h3>
          <ul>
            <li>Structure of the Republic</li>
            <li>Expansion of Roman territories</li>
            <li>Conflict of the Orders</li>
          </ul>
          <h3>Week 3: The Rise of Julius Caesar</h3>
          <ul>
            <li>Julius Caesar's military campaigns</li>
            <li>The First Triumvirate</li>
            <li>Assassination of Julius Caesar</li>
          </ul>
          <h3>Week 4: The Roman Empire</h3>
          <ul>
            <li>Formation of the Empire</li>
            <li>Augustus and the Pax Romana</li>
            <li>Emperors and Dynasties</li>
          </ul>
          <h3>Week 5: Roman Society and Culture</h3>
          <ul>
            <li>Social classes in Rome</li>
            <li>Roman art, literature, and architecture</li>
          </ul>
          <h3>Week 6: Decline and Fall of the Roman Empire</h3>
          <ul>
            <li>Causes of the decline</li>
            <li>Barbarian invasions</li>
            <li>Legacy of the Roman Empire</li>
          </ul>
        `,
        role: "assistant"
      }
    ],
    sharedWith: [],
    __v: 10,
    _id: 1
  }
]

export { 
  ownedMessageFolders, 
  sharedMessageFolders,
  filteredDocs,
  ownedMessages
}