import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react'
import { ChatContainer, ChatInputContainer } from './styled';
import DemoMessages from './DemoMessages';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { createOpenAIRequest } from '../../containers/GPTChat/helpers';
import DemoInput from './DemoInput';
import { demoMessages } from './constants';

function DemoChat() {

  const theme = useTheme();
  const chatContainerRef = useRef();
  const inputRef = useRef();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const userInfo = useSelector((state) => state.userData.loginInfo);
  const userGenCredits = useSelector((state) => state?.userData?.loginInfo?.userCredits);
  const selectedVersion = useSelector((state) => state?.gptChat?.gptVersion);
  const lastCreatedFolder = useSelector((state) => state?.gptChat?.latestCreatedFolder);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(lastCreatedFolder);
  const [addNewFolder, setAddNewFolder] = useState(false);

  // Keeps formatting from carrying over while text is copied.
  document.addEventListener('copy', function(e) {
    e.preventDefault();
    const text = window.getSelection().toString();
    e.clipboardData.setData('text/plain', text);
  });

  const handleClosePopover = () => {
 
  };

  const handleSaveDoc = (selectedDocName) => {

  };
  
  const handleFolderChange = (folderData) => {
    setSelectedFolder(folderData);
  };

  const handleClick = (event, message) => {

  };

  const handleSubtractCredits = () => {

  };

  const handleClose = () => {
    setAddNewFolder(false);
    setSelectedFolder(null);
    setAnchorEl(null);
  };

  const handleSend = async (userMessage) => {

  };

  return (
    <Grid
      sx={{
        ...theme.flexBox.justifyAlignCenter, 
        flexDirection: 'column', 
        width: '100%',
        position: 'relative',
        height: '100%'
      }}
    >
      <Grid 
        item 
        sx={{
          flex: '8', 
          width: '100%', 
          maxHeight: '100dvh', 
          overflow: 'auto',
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center'
        }}
      >
        <ChatContainer ref={chatContainerRef}>
          <DemoMessages
            messages={ demoMessages }
            userInfo={ userInfo }
            addNewFolder={ addNewFolder }
            handleClose={ handleClose }
            setAddNewFolder={ setAddNewFolder }
            setSelectedFolder={ setSelectedFolder }
            selectedFolder={ selectedFolder }
            handleSaveDoc={ handleSaveDoc }
            handleChange={ handleFolderChange }
            handleClick={ handleClick }
            anchorEl={ anchorEl }
            handleClosePopover={ handleClosePopover }
          />
        </ChatContainer>
      </Grid>
      <Grid 
        container 
        sx={{
          flex: '1', 
          width: '100%', 
          height: '100%', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          padding: isMobile && '0 0.5rem'
        }}
      >
        <ChatInputContainer>
          <DemoInput
            inputRef={inputRef} 
            handleSend={handleSend} 
            responseLoading={false}
            selectedVersion={selectedVersion}
            userGenCredits={userGenCredits}
          />
        </ChatInputContainer>
      </Grid>
    </Grid>
  )
}

export default DemoChat;