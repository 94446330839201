import React from 'react'
import ContentArea from '../../components/ContentArea';
import FoldersContainer from '../FoldersDemo/FoldersContainer';
import { DrawerHeader, Main } from '../../styled';
import { useAppContext } from '../LiveDemo/context';
import { useMount } from 'react-use';

function FolderListDemo({ drawerOpen, drawerWidth }) {

  const { setState } = useAppContext();

  useMount(() => {
    setState({ run: true, tourActive: true, stepIndex: 3 });
  }); 

  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<FoldersContainer />} />
    </Main>
  );
}

export default FolderListDemo;