const demoMessages = [
  {
    content: "Write me a simple well structured course outline for an Intro to Roman History.",
    role: "user"
  },
  {
    content: `
      <h2>Course Outline: Introduction to Roman History</h2>
      <h3>Week 1: The Foundation of Rome</h3>
      <ul>
        <li>Legend of Romulus and Remus</li>
        <li>The monarchy period</li>
      </ul>

      <h3>Week 2: The Roman Republic</h3>
      <ul>
        <li>Structure of the Republic</li>
        <li>Expansion of Roman territories</li>
        <li>Conflict of the Orders</li>
      </ul>

      <h3>Week 3: The Rise of Julius Caesar</h3>
      <ul>
        <li>Julius Caesar's military campaigns</li>
        <li>The First Triumvirate</li>
        <li>Assassination of Julius Caesar</li>
      </ul>

      <h3>Week 4: The Roman Empire</h3>
      <ul>
        <li>Formation of the Empire</li>
        <li>Augustus and the Pax Romana</li>
        <li>Emperors and Dynasties</li>
      </ul>

      <h3>Week 5: Roman Society and Culture</h3>
      <ul>
        <li>Social classes in Rome</li>
        <li>Roman art, literature, and architecture</li>
      </ul>

      <h3>Week 6: Decline and Fall of the Roman Empire</h3>
      <ul>
        <li>Causes of the decline</li>
        <li>Barbarian invasions</li>
        <li>Legacy of the Roman Empire</li>
      </ul>
    `,
    role: "assistant"
  }
];

export { demoMessages }