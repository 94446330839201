import React, { 
  useEffect, 
  useMemo, 
  useState 
} from 'react';
import { 
  ParentGrid 
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import FilesViewDesktop from './FilesViewDesktop';
import FilesViewMobile from './FilesViewMobile';
import { 
  CircularProgress, 
  Grid, 
  Typography, 
  useMediaQuery 
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { deleteGPTMessage } from '../../actions/gptChatActions';
import { deleteGPTFolder } from '../../actions/documentActions';

function DocumentManager() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { isAuthenticated } = useAuth0();

  const ownedMessages = useSelector((state) => state.gptChat.ownedDbMessages);
  const externalMessages = useSelector((state) => state.gptChat.externalDbMessages);
  const sharedMessageFolders = useSelector((state) => state.gptChat.externalFolders);
  const ownedMessageFolders  = useSelector((state) => state.gptChat.ownedFolders);

  const dbMessagesLoading = useSelector((state) => state?.gptChat?.dbMessagesLoading);
  const trialUserSavedGPTMessages = useSelector((state) => state.gptChat.trialMessageSaves);
  const userId = useSelector((state) => state.userData.loginInfo.user_id);

  const [selectedFolder, setSelectedFolder] = useState({ _id: 0, name: 'All' });
  const [filteredDocs, setFilteredDocs] = useState(null);
  const [managerView, setManagerView] = useState('Folders');
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [savedFiles, setSavedFiles] = useState(null);

	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const checkOwnedAndExternalMessagesAndReturnArray = () => {
    if(ownedMessages && externalMessages){
      return [...ownedMessages, ...externalMessages];
    };

    if(!ownedMessages && externalMessages){
      return [...externalMessages];
    };

    if(ownedMessages && !externalMessages){
      return [...ownedMessages];
    };
  };

  const handleDeleteFolder = () => {

    dispatch(
      deleteGPTFolder(userId, selectedFolder?._id, dispatch)
    );

    setSelectedFolder({ _id: 0, name: 'All' });
  };

  const handleDeleteFile = () => {
    dispatch(
      deleteGPTMessage(userId, selectedFileData._id, dispatch)
    );

    navigate('/folders');
    setManagerView('Files');
  };

  useMemo(() => {
    setSavedFiles(checkOwnedAndExternalMessagesAndReturnArray());

    // eslint-disable-next-line
  }, [ownedMessages, externalMessages])

  useEffect(() => {

    if(
      selectedFolder && 
      selectedFolder?._id !== 0 && 
      isAuthenticated
    ){
      setFilteredDocs(
        savedFiles?.filter(
          (message) => message?.folderId === selectedFolder._id
        )
      );
      return;
    };

    if(isAuthenticated && (selectedFolder?._id === 0)){
      setFilteredDocs(savedFiles);
      return;
    };

    if(selectedFolder && !isAuthenticated){

      setFilteredDocs(
        trialUserSavedGPTMessages?.filter(
          (message) => message.folderData?._id === selectedFolder._id
        )
      );
      return;
    }  

    if(!selectedFolder && !isAuthenticated){
      setFilteredDocs(trialUserSavedGPTMessages);
      return;
    }
    //eslint-disable-next-line
  }, [selectedFolder, ownedMessages, externalMessages]);

  useEffect(() => {
    
    if(params?.folderId){
      const findFolderById = [...ownedMessageFolders, ...sharedMessageFolders]?.find(
        (folder => folder?._id === params?.folderId)
      );

      setSelectedFolder(findFolderById);
      return;
    };
  
    if (params?.messageId) {
      const _id = params.messageId;
      const updatedFileData = [
        ...(ownedMessages ?? []), 
        ...(externalMessages ?? [])
      ].find((msg) => msg._id === _id);
  
      setSelectedFileData(updatedFileData || null);
      setManagerView('Document');

      const updatedFolder = ownedMessageFolders?.find(
        (folder) => folder?._id === updatedFileData?.folderId
      );

      if(updatedFolder){
        setSelectedFolder(updatedFolder);
      };

    } else {
      const updatedFolder = ownedMessageFolders?.find(
        (folder) => selectedFolder?._id === folder?._id
      );

      if(updatedFolder){
        setSelectedFolder(updatedFolder);
      };

      setManagerView('Folders')
    };
    //eslint-disable-next-line
  }, [
    ownedMessages, 
    externalMessages, 
    ownedMessageFolders, 
    sharedMessageFolders,
    params
  ]);

  return (
    <ParentGrid container>
      {
        dbMessagesLoading ? (
          <Grid 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              flexDirection: 'column',
              alignItems: 'center', 
              width: '-webkit-fill-available', 
              height: '-webkit-fill-available'
            }}
          >
            <Typography variant='h5' sx={{color: 'white', marginBottom: '1rem'}}>
              Loading Messages...
            </Typography>
            <CircularProgress sx={{width: '3rem', height: '3rem', color: '#84A8FF'}}/>
          </Grid>
        ) : (
          
          isMobile ? (
            <FilesViewMobile
              selectedFolder        = { selectedFolder       }
              setSelectedFolder     = { setSelectedFolder    }
              filteredDocs          = { filteredDocs         }
              ownedMessageFolders   = { ownedMessageFolders  }
              sharedMessageFolders  = { sharedMessageFolders } 
              managerView           = { managerView          }
              selectedFileData      = { selectedFileData     }
              setSelectedFileData   = { setSelectedFileData  }
              setManagerView        = { setManagerView       }
              handleDeleteFolder    = { handleDeleteFolder   }
              handleDeleteFile      = { handleDeleteFile     }
            />
          ) : (
            <FilesViewDesktop
              selectedFolder        = { selectedFolder       }
              setSelectedFolder     = { setSelectedFolder    }
              filteredDocs          = { filteredDocs         }
              ownedMessageFolders   = { ownedMessageFolders  }  
              sharedMessageFolders  = { sharedMessageFolders }
              managerView           = { managerView          }
              selectedFileData      = { selectedFileData     }
              setSelectedFileData   = { setSelectedFileData  }
              setManagerView        = { setManagerView       }
              handleDeleteFolder    = { handleDeleteFolder   }
              handleDeleteFile      = { handleDeleteFile     }
            />
          )
        )
      }
    </ParentGrid>
  )
}

export default DocumentManager;