import React from 'react'
import { 
  FolderIconGrid, 
  FolderNameGrid, 
  InnerGridBackgroundContainer, 
  ParentGrid 
} from './styled';
import { Checkbox, Typography } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';

function FolderListItem({
  folderName,
  folderId,
  folderData,
  shared,
  setSelectedFolder,
  setManagerView,
  selectedFolder,
}) {

  const isSelected = () => {
    if(selectedFolder?._id && folderId){
      if(folderId === selectedFolder?._id){
        return true;
      };
    };

    if(folderId === 0 && selectedFolder?._id === 0){
      return true;
    }

    return false;
  };

  const handleSelectFolder = () => {
    setManagerView('Files');

    if(folderData && folderData?._id !== 0){
      setSelectedFolder(folderData);
    } else {
      setSelectedFolder({ _id: 0, name: 'All' })
    };
  };

  return (
    <ParentGrid container onClick={handleSelectFolder}>
      <InnerGridBackgroundContainer container isSelected={isSelected()}>
        <FolderIconGrid item>
          {
            shared ? (
              <FolderSharedOutlinedIcon sx={{color: isSelected() ? '#507CE6' : 'gray', fontSize: '2rem'}} />
            ) : (
              <FolderOutlinedIcon sx={{color: isSelected() ? '#507CE6' : 'gray', fontSize: '2rem'}} />
            )
          }
        </FolderIconGrid>
        <FolderNameGrid item>
          <Typography variant='h5' sx={{color: isSelected() ? '#507CE6' : 'gray'}}>
            {folderName}
          </Typography>
        </FolderNameGrid>
      </InnerGridBackgroundContainer>
    </ParentGrid>
  )
}

export default FolderListItem;