import { 
  CLEAR_TEMP_INVITE_DATA, 
  COLLABORATOR_USER_INFO_FAIL, 
  COLLABORATOR_USER_INFO_REQUEST, 
  COLLABORATOR_USER_INFO_SUCCESS, 
  INVITE_DELIVER_FAIL, 
  STORE_TEMP_INVITE_DATA 
} from "../consts/collaboratorsConstants";

export const collaboratorsReducer = (state = {}, action) => {
  switch(action.type){
    case COLLABORATOR_USER_INFO_REQUEST: 
      return {
        ...state,
        loadingCollaborators: true
      };
    case COLLABORATOR_USER_INFO_SUCCESS:
      return {
        ...state,
        retrievedCollaborators: [
          ...state.retrievedCollaborators, 
          ...action.payload
        ],
        loadingCollaborators: false
      };
    case COLLABORATOR_USER_INFO_FAIL:
      return {
        ...state,
        collaboraterRetrieveError: action.payload,
        loadingCollaborators: false
      };
    case STORE_TEMP_INVITE_DATA:
      return {
        ...state,
        invites: {
          sendingInvite: true,
          tempInviteInfo: action.payload,
          inviteSendError: false
        }
      }
    case CLEAR_TEMP_INVITE_DATA: 
      return {
        ...state,
        invites: {
          sendingInvite: false,
          tempInviteInfo: {},
          inviteSendError: false
        }
      }
    case INVITE_DELIVER_FAIL:
      return {
        ...state,
        invites: {
          ...state.invites,
          sendingInvite: false,
          inviteSendError: true,
          tempInviteInfo: {
            permission: state.invites.tempInviteInfo.permission,
            email: state.invites.tempInviteInfo.email,
            inviteFailed: true
          }
        }
      }
      
    default: return {
      ...state
    };
  };
};