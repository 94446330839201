import React, { useEffect } from 'react';
import { DrawerHeader, Main } from '../../styled';
import DashboardContainer from './DashboardContainer';
import ContentArea from '../../components/ContentArea';
import { useAppContext } from '../LiveDemo/context';
import { useParams } from 'react-router-dom';


function DashboardDemo({ drawerOpen, drawerWidth }) {

  const params = useParams();
  const stepId = parseInt(params?.id);
  const { setState } = useAppContext();

  useEffect(() => {
    switch(stepId){
      case (5):
        setState({ run: true, tourActive: true, stepIndex: 5 });
      break;
      case (6):
        setState({ run: true, tourActive: true, stepIndex: 6 });
      break;
      case (7):
        setState({ run: true, tourActive: true, stepIndex: 7 });
      break;
      case (8):
        setState({ run: true, tourActive: true, stepIndex: 8 });
      break;
      default: setState({ run: true, tourActive: true, stepIndex: 5 });
    };
  }, [setState, stepId]);


  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<DashboardContainer />} />
    </Main>
  );
};

export default DashboardDemo;