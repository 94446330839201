import React, { useState } from 'react';
import { 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  TextField, 
  Button, 
  MenuItem 
} from '@mui/material';
import gtmTrackButtonClick from '../../utils/gtmTrackButtonClick';

function FolderAddCollaboratorDialog({
  open, 
  onClose, 
  onConfirm, 
  isMobile
}) {
  
  const [email, setEmail] = useState('');
  const [permission, setPermission] = useState('');

  const handleConfirmAdd = (e) => {
    gtmTrackButtonClick(e, 'click_addCollaborator');
    // Handle the confirm add action here. For example, call an API or update the state.
    onConfirm({ email, permission });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Collaborator</DialogTitle>
      <DialogContent sx={{ width: isMobile ? '20rem' : '25rem'}}>
        <TextField
          required
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ width: isMobile ? '17rem' : '100%'}}
        />
        <TextField
          required
          margin="dense"
          label="Permission"
          select
          fullWidth
          value={permission}
          onChange={(e) => setPermission(e.target.value)}
          sx={{ width: isMobile ? '17rem' : '100%'}}
        >
          <MenuItem value="canEdit">Can Edit</MenuItem>
          <MenuItem value="canView">Can View</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button 
          variant='contained' 
          onClick={handleConfirmAdd} 
          disabled={!email || !permission}
          sx={{
            background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)`,
            color: 'white' 
          }}
        >
          Confirm Add
        </Button>
        <Button 
          variant='outlined' 
          onClick={onClose} 
          sx={{ color: 'gray', borderColor: 'lightgray'}}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FolderAddCollaboratorDialog;